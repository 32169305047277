import React from 'react'
import { useTranslation } from 'react-i18next';
import comingSoonImg from "../../../../../assets/images/stage-planner-coming-soon.png";
import { discordLink } from '../../../../../app/constants';
import './StagePlanner.scss';
import { StageHeader } from '../stage-header/StageHeader';
import { getDateWithYear } from '../../../../../shared/utils/dateFormat';

export const StagePlanner = () => {
  const { t } = useTranslation();

  const buildParagraph = (): string => {
    const checkboxHtmlString = t("stageComingSoonParagraph")
      .replace('{join_our_discord}', `<a href=${discordLink} target="_blank" rel="noreferrer">${t('joinOurDiscordLinkPart1')}</a>`)
    return checkboxHtmlString;
  }

  return (
    <div className='stage-planner'>
      <StageHeader>
          <h2 className="title static-string">{getDateWithYear(new Date(Date.now()).toString(), true, false)}</h2>
      </StageHeader>
      <div className='stage-content-container stage-planner-container'>
        <div className="scrollable-container">
          <h2 className='stage-content-title static-string'>{t('stageComingSoonTitle')}</h2>
          <div className='stage-content-image-container'><img className='stage-content-img' src={comingSoonImg} alt={t('stageComingSoonTitle')} /></div>
          <p className='stage-content-para' dangerouslySetInnerHTML={{ __html: buildParagraph() }} />
        </div>
      </div>
    </div>
  )
}