import { FunctionComponent,useEffect,useCallback, useRef } from 'react';
import { useAppDispatch } from '../../store';
import { loginReqAction } from '../auth.store';
import { APP_ROUTING_PATHS, loginTypeStorageKey, redirectUrlStorageKey } from '../../constants';
import './CheckLogin.scss';
import { ApplicationInsightsApi } from '../../../application-insights';
import { useLocalStorage } from '../../../shared/utils/useLocalStorage';
import { LottieAppLoader } from '../../../shared/components/lottie-loader/LottieLoader';

export const CheckLogin: FunctionComponent  = () => {
  const dispatch = useAppDispatch();
  // const abortControllerRef = useRef(new AbortController());
  const [, setLoginType] = useLocalStorage(loginTypeStorageKey, '');
  const [, setRedirectUri] = useLocalStorage(redirectUrlStorageKey, '');
  const isFetchingDataRef = useRef(false);

  const collectTokenAndSendToServer = useCallback(() => {
    const UrlReplaysHashWithSearch = new URL(window.location.href);
    const accessToken = UrlReplaysHashWithSearch.searchParams.get('code') || "";
    const loginType = UrlReplaysHashWithSearch.searchParams.get('state') || "";
    const redirectUri = window.location.origin + APP_ROUTING_PATHS.SSO;
    setLoginType(loginType)
    setRedirectUri(redirectUri)
    dispatch(loginReqAction({payload:{accessToken,redirectUri}, loginType }))
    .unwrap()
    .then(() => isFetchingDataRef.current = false)
    .catch(e => {
      ApplicationInsightsApi.trackException(e);
      isFetchingDataRef.current = false;
    });
  },[dispatch, setLoginType, setRedirectUri])
  
  useEffect(() => {
    if (!isFetchingDataRef.current) {
      isFetchingDataRef.current = true;
      collectTokenAndSendToServer();
    }
  }, [collectTokenAndSendToServer]);


  return (
    <div className="sso">
      <LottieAppLoader testId='lottie-sso-loader' />
    </div>
  )
}