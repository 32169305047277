import React, { FunctionComponent, useEffect } from 'react';
import { EAppStageContentType } from '../stage-container/stageContainer.interfaces';
import { useSearchParams } from 'react-router-dom';
import { settingsMenuParam, stageParam } from '../../../../app/constants';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import { setAppModalContentType } from '../../../../shared/components/app-modals/appModals.store';
import { EAppModalContentType } from '../../../../shared/components/app-modals/appModals.interfaces';
import { resetBotResponse } from '../../chat.store';

interface INavButtonProps {
  className: string;
  type: EAppStageContentType;
  defaultIcon: string;
  activeIcon: string;
}

export const NavButton: FunctionComponent<INavButtonProps> = ({ className, type, defaultIcon, activeIcon }) => {
  const { appModalContentType } = useAppSelector(store => store.appModalsReducer);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // open close settings modal by the params
    if(searchParams.get(settingsMenuParam) && appModalContentType !== EAppModalContentType.SETTINGS){
      dispatch(setAppModalContentType(EAppModalContentType.SETTINGS));
    } else if(!searchParams.get(settingsMenuParam) && appModalContentType === EAppModalContentType.SETTINGS){
      dispatch(setAppModalContentType(null));
    }
  }, [appModalContentType, dispatch, searchParams])
  

  const isTabActive = (tab: EAppStageContentType) => {
    // chat is the default
    if (!searchParams.get(stageParam) && tab === EAppStageContentType.CHAT) return true;
    return tab === searchParams.get(stageParam);
  }

  const onClickTab = (tab: EAppStageContentType) => {
    if(tab === EAppStageContentType.TASKS) dispatch(resetBotResponse());
    // by default delete the stage and the settings params 
    setSearchParams(prev => {
      prev.delete(settingsMenuParam);
      prev.delete(stageParam);
      return prev;
    });
    // if not chat replace the stageParam
    if (tab !== EAppStageContentType.CHAT) {
      setSearchParams(prev => {
        prev.append(stageParam, tab);
        return prev;
      });
    }
  }

  return (
    <button className={`${className} ${isTabActive(type) && 'active'}`} onClick={() => onClickTab(type)}>
      <img src={isTabActive(type) ? activeIcon : defaultIcon} alt={type} />
    </button>
  )
};