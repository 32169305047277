import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { scrollToTheBottomOfScrollableEl } from '../../../../../shared/utils/scrollToTheBottomOfScrollableEl';
import '../Message.scss';

const ErrorMessage = ({errorText}:{errorText?:string}) => {
  const { t } = useTranslation();

  useEffect(() => {
    scrollToTheBottomOfScrollableEl('.chat-container .conversation');
  },[]);

  return (
    <div className='message reject-error'>
      <div className="msg-content" data-testid='reject-error'>{errorText || t("somethingWentWrongCanYouTryAgainError")}</div>
    </div>
  )
}

export default memo(ErrorMessage);