import React, { Dispatch, FunctionComponent, SetStateAction } from 'react'
import { ITaskEditorOverlayType } from '../../TaskEditOverlay';
import TaskEditOverlayQuickOptionItem from '../../TaskEditOverlayQuickOptionItem';
import { calculateEveningBeforeRelativeDate, calculateMinutesBeforeRelativeDate, calculateMorningOfRelativeDate, getTimeInSeconds, secondsFromMorning, secondsFromEveningBefore } from '../../TaskEditOverlay.utils';
import './QuickOptionsReminder.scss';
import { useTranslation } from 'react-i18next';

interface IQuickOptionsReminderProps {
  setReminderOverlayType: Dispatch<SetStateAction<"quickOptions" | "custom" | null>>;
  setSelectedReminder: Dispatch<SetStateAction<number | null | undefined>>;
  handleSet?: (() => void) ;
  editOverlayType: ITaskEditorOverlayType;
  relativeDate: Date;
}

const QuickOptionsReminder: FunctionComponent<IQuickOptionsReminderProps> = ({ setSelectedReminder, setReminderOverlayType, relativeDate, editOverlayType, handleSet }) => {
  const { t } = useTranslation();
  
  const dueDateReminderQuickOption = [
    {
      header: t("createOrEditTaskDueDateReminderQuickOption1"),
      date: calculateEveningBeforeRelativeDate(relativeDate),
      action: () => setSelectedReminder(secondsFromEveningBefore(relativeDate))
    },
    {
      header: t("createOrEditTaskDueDateReminderQuickOption2"),
      date: calculateMorningOfRelativeDate(relativeDate),
      action: () => setSelectedReminder(secondsFromMorning(relativeDate))
    },
    {
      header: t("createOrEditTaskDueDateReminderQuickOption3"),
      date: calculateMinutesBeforeRelativeDate(relativeDate, 15),
      action: () => setSelectedReminder(getTimeInSeconds("minute", 15))
    }
  ]

  const workTimeReminderQuickOption = [
    {
      header: t("createOrEditTaskWorkTimeReminderQuickOption1"),
      action: () => setSelectedReminder(getTimeInSeconds("minute", 5))
    },
    {
      header: t("createOrEditTaskWorkTimeReminderQuickOption2"),
      action: () => setSelectedReminder(getTimeInSeconds("minute", 15))
    },
    {
      header: t("createOrEditTaskWorkTimeReminderQuickOption3"),
      action: () => setSelectedReminder(getTimeInSeconds("hour", 1))
    }
  ]

  const getOptionsByOverlayType = () => {
    switch (editOverlayType) {
      case "dueDate":
        return dueDateReminderQuickOption;
      case "workTime":
        return workTimeReminderQuickOption;
      default:
        return [];
    }
  }

  return (
    <div className='quick-options-reminder'>
      {getOptionsByOverlayType().map(option => (
        <div key={option.header} onClick={() => {handleSet ? handleSet() : setReminderOverlayType(null)}} className={`quick-option-reminder-${editOverlayType}`}>
          <TaskEditOverlayQuickOptionItem option={option} />
        </div>
      ))}
      <button className='open-custom-register' onClick={() => setReminderOverlayType("custom")}>
        <span>{t("or")}</span>
        {t("custom")}
      </button>
    </div>
  )
}

export default QuickOptionsReminder
