import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AddPhoneRegistrationForm } from '../../auth.interfaces';
import { useAppDispatch, useAppSelector } from '../../../store';
import { updatePhoneNumberReqAction } from '../../auth.store';
import { APP_ROUTING_PATHS, privacyPolicyLink, termsOfServiceLink, userInfoLocalStorageKey, userPhoneLength } from '../../../constants';
import { ContentFrameWrapper } from '../../../../shared/components/content-frame-wrapper/ContentFrameWrapper';
import { useApiData } from '../../../../shared/hooks/useApiData';
import { useNavigate } from 'react-router-dom'
import '../Register.scss';
import './AddUserPhoneRegistrationForm.scss';
import { useLocalStorage } from '../../../../shared/utils/useLocalStorage';
import { phoneFormatter } from '../../../../shared/utils/phoneFormatter';

export const AddUserPhoneRegistrationForm: FunctionComponent = () => {
  const { register, handleSubmit, setFocus, formState, reset, setValue } = useForm<AddPhoneRegistrationForm>({
    defaultValues: new AddPhoneRegistrationForm()
  });
  const { updatePhoneNumberRes } = useAppSelector(store => store.authReducer)
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const resetPhoneTimer = useRef<NodeJS.Timeout | null>(null);
  const [shouldDisplayError, setShouldDisplayError] = useState(false);
  const [, setUserInfo] = useLocalStorage(userInfoLocalStorageKey, '');
  const navigate = useNavigate();

  useEffect(() => {
    // set auto focus on the userPhone input
    setFocus(`userPhone`);

    return () => {
      // clear Timeout when the component destroyed
      if (resetPhoneTimer.current) clearTimeout(resetPhoneTimer.current);
    }
  }, [setFocus])

  // concat the user phone number to a string and send to the server.
  const onSubmit = useCallback((formData: AddPhoneRegistrationForm) => {
    dispatch(updatePhoneNumberReqAction({ phoneNumber: `+1${formData.userPhone.replaceAll('-', '')}` }));
  }, [dispatch])

  // reset and set focus the phone text-box input on Rejected after 500ms
  const resetPhoneInput = () => {
    if (resetPhoneTimer.current) clearTimeout(resetPhoneTimer.current);
    resetPhoneTimer.current = setTimeout(() => {
      setFocus('userPhone');
      reset({ 'userPhone': '' });
    }, 500);
  }

  useApiData(updatePhoneNumberRes, {
    onFulfilled(updatePhoneNumberResData) {
      if (updatePhoneNumberResData.id) {
        setUserInfo(updatePhoneNumberResData);
        navigate(APP_ROUTING_PATHS.REDIRECT);
      }
    },
    //  reset phone input when registration API rejected
    onRejected() {
      setShouldDisplayError(true);
      resetPhoneInput();
    },
  });

  const handlePhoneNumberFormatChange = (event: any) => {
    const inputValue = event.target.value;
    setValue('userPhone', phoneFormatter(inputValue, '-'));
  };

  return (
    <div className="add-phone-registration-page add-phone-form-page" id="update-phone-page" data-testid="update-phone-page">
      <ContentFrameWrapper className='add-phone-frame-wrapper'>
        <form id="update-phone-form" onSubmit={handleSubmit(onSubmit)} data-testid="update-phone-form">
          <h1 className='title'>{t('registrationScreenTitle')}</h1>
          <label htmlFor='userPhone' className='add-phone-label'>{t("addPhoneRegistrationScreenSubTitle")}</label>
          <div className="registration-input-container">
            <input
              {...register('userPhone', { required: true, minLength: userPhoneLength, maxLength: userPhoneLength, onChange: (e) => { handlePhoneNumberFormatChange(e); shouldDisplayError && setShouldDisplayError(false) } })}
              id={`user-phone-input`}
              data-testid={`user-phone-input`}
              type="tel"
              maxLength={userPhoneLength}
              className={`register-input ${formState.isValid && 'valid-mode'} ${shouldDisplayError && 'red-border-error'}`}
              placeholder='XXX-XXX-XXXX'
            />
            {/* if there is error message from the server display it, else display static message */}
            {shouldDisplayError && <small className='error' data-testid="update-phone-error">{t("enterValidPhoneNumberError")}</small>}
          </div>
          <p className='agreement-paragraph' data-testid="update-phone-agreement">
            {t("addPhoneAgreementParagraphPart1")}{" "}
            <a href={termsOfServiceLink} target="_blank" rel="noreferrer">{t("addPhoneAgreementParagraphPart2")}</a>{" "}
            {t("addPhoneAgreementParagraphPart3")}{" "}
            <a href={privacyPolicyLink} target="_blank" rel="noreferrer">{t("addPhoneAgreementParagraphPart4")}</a>{" "}
            {t("addPhoneAgreementParagraphPart5")}{" "}
          </p>
          <div className="next-container">
            <button className='auth-next-btn' form="update-phone-form" id="update-phone-form-submit" data-testid="update-phone-form-submit" type='submit' disabled={!formState.isValid || formState.isSubmitted} >{t('registerScreenNextButtonText')}</button>
          </div>
        </form>
      </ContentFrameWrapper>
    </div>
  )
}