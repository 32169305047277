import React, { FunctionComponent } from 'react';
import { UserAvatarHeader } from './user-avatar/UserAvatarHeader';
import { ToggleHistoryHeaderIcon } from './toggle-history-icon/ToggleHistoryHeaderIcon';
import { StageTasksDropdownFilters } from '../../../../features/chat-wrapper/resizable-container/stage-container/stage-tasks/stage-tasks-dropdown-filters/StageTasksDropdownFilters';
import collapseIcon from '../../../../assets/images/collapse-icon.svg';
import { setShouldStageExpand } from '../../../../features/chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.store';
import { useAppDispatch } from '../../../../app/store';
import './Header.scss';

export const Header: FunctionComponent = () => {
  const dispatch = useAppDispatch();

  return (
    <header className="header-container">
      <button type="button" className='minimize-stage' onClick={() => dispatch(setShouldStageExpand(false))}><img src={collapseIcon} alt="collapse" /></button>
      <div className='header-content'>
        <StageTasksDropdownFilters />
        <ToggleHistoryHeaderIcon />
        <UserAvatarHeader />
      </div>
      <div className='separation'></div>
    </header>
  )
}