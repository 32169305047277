import React, { FunctionComponent, HTMLInputTypeAttribute } from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormRegisterReturn } from 'react-hook-form';
import penEditIcon from '../../../../../../assets/images/pen-edit.svg';
import { IProfileForm } from './EditProfileForm';
import { ErrorMessage } from '../../error-message/ErrorMessage';

interface IEditItemSectionProps {
  desktopLabel: string;
  mobileLabel: string;
  inputValue: string;
  inputName: keyof IProfileForm;
  isInputEditable: boolean;
  register: UseFormRegisterReturn<keyof IProfileForm>;
  onFocusInput: () => void
  shouldDisplayError: boolean;
  editTestId: string;
  inputType: HTMLInputTypeAttribute;
  inputClassName?: string;
  maxLengthInput?: number;
}

export const EditItemSection: FunctionComponent<IEditItemSectionProps> = ({ inputName, inputClassName, editTestId, inputType, desktopLabel, mobileLabel, inputValue, isInputEditable, register, onFocusInput, shouldDisplayError, maxLengthInput }) => {
  const { t } = useTranslation();

  return (
    <section>
      <label htmlFor={inputName}>
        <span className='desktop-label-text static-string'>{desktopLabel}</span>
        <span className='mobile-label-text static-string'>{mobileLabel}</span>
      </label>
      <div>
        {isInputEditable
          ?
          <input
            className={inputClassName}
            type={inputType}
            id={inputName}
            autoFocus
            maxLength={maxLengthInput}
            data-testid={inputName}
            {...register}
          />
          :
          <div className='flex'>
            <button type='button' className='edit' onClick={() => onFocusInput() } data-testid={editTestId}>
              <img src={penEditIcon} alt="pen" />
              {t("edit")}
            </button>
            <h4 onClick={() => onFocusInput()} data-testid={`${inputName}Title`}>{inputValue}</h4>
          </div>
        }
      </div>
      {shouldDisplayError && <ErrorMessage />}
    </section>
  )
}