import { useEffect, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarSyncPopover } from '../calendar-sync/CalendarSyncPopover';
import createAppOverlayPopover from '../../../../app-overlay-popover/createAppOverlayPopover';
import { useAppDispatch, useAppSelector } from '../../../../../../app/store';
import GoogleLogo from '../../../../../../assets/images//Google-logo.png';
import penEditIcon from '../../../../../../assets/images/pen-edit.svg';
import './CalendarSync.scss';
import { chatSessionIdSessionStorageKey } from '../../../../../../app/constants';
import { useLocalStorage } from '../../../../../utils/useLocalStorage';
import { IUserProfileReqPayload } from '../../SettingsMenu.interfaces';
import { updateUserProfileReq } from '../../settingsMenu.store';

export const CalendarSync = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { getUserProfile } = useAppSelector((store) => store.settingsMenuReducer);
    const [calendarProvider, setCalendarProvider] = useState<null | string>(null);
    const { sessionId } = useAppSelector(store => store.chatReducer);
    const [sessionIdSessionStorage,] = useLocalStorage(chatSessionIdSessionStorageKey, '');

    useEffect(() => {
        setCalendarProvider(getUserProfile.data?.calendarProvider || null);
    }, [getUserProfile])  

    const handleRemoveCalendarSync = () => {
        const reqPayload: IUserProfileReqPayload = { 
            shouldRemoveCalendar: true,
            sessionId: sessionId?.data?.sessionId || sessionIdSessionStorage
         };
        dispatch(updateUserProfileReq(reqPayload))
        .then((userData) => {if(userData) setCalendarProvider(null)});
    };

    return (
        <Fragment>
            <section className="data">
                <div>
                    <h3 className="title static-string">{t('settingsPersonalInfoCalendarTitle')}</h3>
                    <p>{t('settingsPersonalInfoCalendarSubTitle')}</p>
                </div>

                {calendarProvider ? (
                    <div className='flex calendar-sync-flex'>
                        <button type='button' className='remove calendar-sync-remove' 
                            onClick={() => {handleRemoveCalendarSync()}}>
                            <img src={penEditIcon} alt="pen" />
                            {t("remove")}
                        </button>
                        <button type='button' className="calendar-sync-google-synced">
                            <img src={GoogleLogo} alt={t('loginGoogleLogoImageAltText')} />
                            {t('google')}
                        </button>
                    </div>
                ) : (
                    <button
                        className="personal-info-btn"
                        data-testid="calendar-sync"
                        onClick={(e) => createAppOverlayPopover(<CalendarSyncPopover />)}
                    >
                        {t('settingsPersonalInfoCalendarButton')}
                    </button>
                )}
            </section>
        </Fragment>
    );
};
