import React, { Fragment } from 'react'
import SingleTag from './SingleTag';
import plusIcon from "../../../../../../../assets/images/plus-icon.svg";
import { IAddEditTaskForm, ITaskFields, ITaskTag } from '../../stageTasks.interface';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import createAppOverlayPopover from '../../../../../../../shared/components/app-overlay-popover/createAppOverlayPopover';
import { TaskEditOverlay } from '../../task-edit-overlays/TaskEditOverlay';
import "./TaskTags.scss";

interface ITaskTagsProps {
  onUpdateTask: (updatedField: ITaskFields, shouldSendMsgToTheBot?: boolean) => void
}

const TaskTags = ({ onUpdateTask }: ITaskTagsProps) => {
  const taskForm = useFormContext<IAddEditTaskForm>();
  const tagsWatch = taskForm.watch("tags");
  const { t } = useTranslation();

  const onDeleteTag = (index: number) => {
    // remove the tag from the list
    const tempTags = [...tagsWatch || []];
    tempTags.splice(index, 1);
    taskForm.setValue("tags", tempTags);
    onUpdateTask({ tags: tempTags }, false);
  }

  const onAddTag = (tag: ITaskTag) => {
    const updatedTags = [...tagsWatch || [], tag];
    taskForm.setValue("tags", updatedTags);
    onUpdateTask({ tags: updatedTags }, false);
  }

  return (
    <div className='task-tags-container'>
      <h3 className='create-or-edit-sub-title'>{t("createOrEditTaskTagsTitle")}</h3>
      <div className="tags-list">
        {(tagsWatch || []).map((tag, i) => <Fragment key={i}><SingleTag tag={tag} index={i} onDeleteTag={onDeleteTag} /></Fragment>)}
        <button
          className='add-tag'
          type='button'
          onClick={() =>
            createAppOverlayPopover(
              <TaskEditOverlay
                overlayType='tags'
                onChange={(tag) => !!tag && onAddTag(tag as ITaskTag)} />
            )}
        >
          <img src={plusIcon} alt="plus" /> {t("createOrEditTaskAddTagButtonText")}
        </button>
      </div>
    </div>
  )
}

export default TaskTags
