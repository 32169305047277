import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { isTheImageLoading } from '../../utils/isTheImageLoading';
import './Avatar.scss';

interface IAvatarProps {
  avatarImg: string;
  defaultContent: React.ReactNode;
  className?: string;
  defaultAvatarClassName?: string;
  size: number;
  onClick?: () => void;
}

export const Avatar: FunctionComponent<IAvatarProps> = ({ className, defaultAvatarClassName, size, avatarImg, defaultContent,onClick }) => {
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [isImageLoading, setImageLoading] = useState(!!avatarImg);

  const loadProfileImage = useCallback(() => {
    isTheImageLoading(avatarImg)
      .then(url => { setProfileImage(url); setImageLoading(false) })
      .catch(() => setImageLoading(false))
  }, [avatarImg])

  useEffect(() => {
    if (isImageLoading) loadProfileImage();
  }, [loadProfileImage, isImageLoading]);

  const avatarInlineStyle = {
    width: `${size}px`,
    height: `${size}px`,
    minWidth: `${size}px`,
    minHeight: `${size}px`,
    backgroundImage: `url(${profileImage ?? ""})`
  }

  const shouldShowDefault = () => {
    return !isImageLoading && !!!profileImage;
  }

  return (
    <div className={`avatar ${className} ${shouldShowDefault() ? `${defaultAvatarClassName} default-avatar` : ''}`} style={avatarInlineStyle} data-testid="avatar" onClick={onClick}>
      {shouldShowDefault() ? defaultContent : ''}
    </div>
  )
}