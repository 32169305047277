import React, { FunctionComponent, ReactNode } from "react";
import penEdit from '../../../assets/images/pen-edit.svg';
import './CardWrapper.scss';

interface ICardWrapperProps {
  children?: ReactNode[] | ReactNode;
  className?: string;
}

const CardWrapper: FunctionComponent<ICardWrapperProps> = ({ children, className }) => {

  return (
    <div className={`card-wrapper ${className ? className : ''}`}>
      <div className="card-header">
        <img src={penEdit} alt="edit" />
      </div>
      {children}
    </div>
  );
}

export default CardWrapper;

