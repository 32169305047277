import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EditProfileForm } from './edit-profile-form/EditProfileForm';
import { ResetProfile } from './reset-profile/ResetProfile';
import { useAppDispatch } from '../../../../../app/store';
import { getUserProfileReq } from '../settingsMenu.store';
import './PersonalInfo.scss';
import { CalendarSync } from './calendar-sync/CalendarSync';
import { userInfoLocalStorageKey } from '../../../../../app/constants';
import { useLocalStorage } from '../../../../utils/useLocalStorage';

export const PersonalInfo = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [userInfo,] = useLocalStorage(userInfoLocalStorageKey, '');

  useEffect(() => {
    dispatch(getUserProfileReq());
  }, [dispatch])

  return (
    <section className="personal-info settings-section-container">
      <h2 className='sub-title static-string'>{t("settingsPersonalInfoTitle")}</h2>
      <div className='separation'></div>
      <EditProfileForm />
      {userInfo?.isInternalUser && <CalendarSync />}
      <ResetProfile />
    </section>
  )
}