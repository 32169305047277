import React from 'react'
import { useFormContext } from 'react-hook-form';
import { onResizeTextareaHeightByTheContext } from '../../../../../../../shared/utils/utils';
import { useTranslation } from 'react-i18next';
import { IAddEditTaskForm } from '../../stageTasks.interface';
import backIcon from "../../../../../../../assets/images/thicker-2px-stroke-back-icon.svg";
import "./EditTaskNotesModal.scss";

const EditTaskNotesModal = ({ onCloseModal }: { onCloseModal: () => void }) => {
  const taskForm = useFormContext<IAddEditTaskForm>();
  const { t } = useTranslation();

  const onClear = () => {
    taskForm.setValue("notes", "");
  }

  return (
    <div className='edit-notes-modal--container'>
      <div className='edit-notes-modal--actions'>
        <button className="edit-notes-modal--actions--back" onClick={onCloseModal}><img src={backIcon} alt="back" /></button>
        <button className="edit-notes-modal--actions--clear" onClick={onClear}>{t("addEditTaskNotesFullPageModalClearButton")}</button>
      </div>
      <textarea
        {...taskForm.register("notes", { required: false, minLength: 1 })}
        className='edit-notes-modal--text-box'
        placeholder={t("addEditTaskNotesFullPageModalPlaceholder")}
        onInput={(e) => onResizeTextareaHeightByTheContext(e?.currentTarget)}
        autoFocus
      />
    </div>
  )
}

export default EditTaskNotesModal
