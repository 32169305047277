import { useState, useRef, useEffect } from 'react';
import { isMobileDevice } from '../../../../../../shared/utils/isMobileDevice';
import './TaskEditOverlayDurationPicker.scss';

export interface ITasksEditOverlayDurationPickerProps {
    onChooseDuration: (duration: number) => void;
    initialDuration?: number | null;
}

export interface IDurationOption {
    name: string;
    value: number;
}


export const TaskEditOverlayDurationPicker = ({ onChooseDuration, initialDuration }: ITasksEditOverlayDurationPickerProps) => {
    const [isMobile] = useState(isMobileDevice());
    const options = [{ name: '15 minutes', value: 15 * 60 }, { name: '30 minutes', value: 30 * 60 }, { name: '1 hour', value: 60 * 60 }];
    const [durationInSeconds, setDurationInSeconds] = useState(initialDuration);
    const [selectedHours, setSelectedHours] = useState(durationInSeconds ? Math.floor(durationInSeconds / 3600) : 0);
    const [selectedMinutes, setSelectedMinutes] = useState(durationInSeconds ? Math.floor((durationInSeconds % 3600) / 60) : 0);
    const selectHoursRef = useRef<HTMLSelectElement>(null);
    const selectMinutesRef = useRef<HTMLSelectElement>(null);

    useEffect(() => {
        setDurationInSeconds(initialDuration);
        setSelectedHours(initialDuration ? Math.floor(initialDuration / 3600) : 0);
        setSelectedMinutes(initialDuration ? Math.floor((initialDuration % 3600) / 60) : 0);
    }, [initialDuration]);

    const handleChangeHours = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = Number(e.target.value);
        setSelectedHours(selectedValue);
        setDurationInSeconds(getSeconds(selectedValue, selectedMinutes));
        onChooseDuration(getSeconds(selectedValue, selectedMinutes));
        selectHoursRef.current?.blur();
    }

    const handleChangeMinutes = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = Number(e.target.value);
        setSelectedMinutes(selectedValue);
        setDurationInSeconds(getSeconds(selectedHours, selectedValue));
        onChooseDuration(getSeconds(selectedHours, selectedValue));
        selectMinutesRef.current?.blur();
    }

    const handlePresetChange = (duration: number) => {
        setDurationInSeconds(duration);
        setSelectedHours(Math.floor(duration / 3600));
        setSelectedMinutes(Math.floor((duration % 3600) / 60));
        onChooseDuration(duration);
    }

    const incrementHours = () => {
        setSelectedHours(selectedHours + 1);
        setDurationInSeconds(getSeconds(selectedHours + 1, selectedMinutes));
        onChooseDuration(getSeconds(selectedHours + 1, selectedMinutes));
    };

    const decrementHours = () => {
        if ((durationInSeconds || 0) < 3600) return;
        const newHours = selectedHours - 1;
        setSelectedHours(newHours);
        setDurationInSeconds(getSeconds(newHours, selectedMinutes));
        onChooseDuration(getSeconds(newHours, selectedMinutes));
    };

    const incrementMinutes = () => {
        if (selectedMinutes >= 60) return;
        const newMinutes = selectedMinutes + 5;
        setSelectedMinutes(newMinutes);
        setDurationInSeconds(getSeconds(selectedHours, newMinutes));
        onChooseDuration(getSeconds(selectedHours, newMinutes));
    };

    const decrementMinutes = () => {
        if ((selectedMinutes || 0) <= 0) return;
        const newMinutes = selectedMinutes - 5;
        setSelectedMinutes(newMinutes);
        setDurationInSeconds(getSeconds(selectedHours, newMinutes));
        onChooseDuration(getSeconds(selectedHours, newMinutes));
    };

    const handleOpenDropDown = (ref: React.RefObject<HTMLSelectElement>) => {
        if (ref.current && isMobile) ref.current.focus();
    }

    const arrowUp = () => {
        return (<svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none">
            <path d="M11.3486 7.5L7.7339 4.5L4.11917 7.5" stroke="#D9D9D9" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>)
    }

    const arrowDown = () => {
        return (<svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none">
            <path d="M4.11816 4.5L7.73289 7.5L11.3476 4.5" stroke="#D9D9D9" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>)
    }

    const getSeconds = (hours: number, minutes: number) => {
        return hours * 3600 + minutes * 60;
    }

    return (
        <div className='task-edit-overlay-duration-picker-container'>
            <div className='task-edit-overlay-duration-picker-preset-options-container'>
                {options.map(option => <button className={`no-style-button duration-preset-button${durationInSeconds === option.value ? ' duration-preset-button--selected' : ''}`} key={option.name} onClick={() => handlePresetChange(option.value)}>{option.name}</button>)}
            </div>
            <h3 className='task-edit-overlay-duration-picker-custom-selector-header'><span>or </span>Custom</h3>
            <div className='task-edit-overlay-duration-custom-time-picker-container'>
                <div className='task-edit-overlay-duration-custom-time-picker task-edit-overlay-duration-custom-time-picker--hours'>
                    <span className='task-edit-overlay-duration-custom-time-picker-value' onClick={() => handleOpenDropDown(selectHoursRef)}>{selectedHours} {selectedHours === 1 ? 'Hour' :'Hours'}</span>
                    {isMobile && <select value={selectedHours} onChange={handleChangeHours} 
                                         className='hidden-select-element' 
                                         name="duration-selector" 
                                         ref={selectHoursRef} 
                                         onFocus={e => { e.currentTarget.size = 6; e.currentTarget.style.zIndex = "1" }} 
                                         onBlur={e => { e.currentTarget.size = 0; e.currentTarget.style.zIndex = "-1" }}>
                        {Array.from(Array(24).keys()).map(hour => <option key={hour} value={hour}>{hour}</option>)}
                    </select>}
                    <div className='task-edit-overlay-duration-picker-action-buttons-container'>
                        <button className='no-style-button task-edit-overlay-duration-picker-action-button' onClick={incrementHours}>{arrowUp()}</button>
                        <button className='no-style-button task-edit-overlay-duration-picker-action-button' onClick={decrementHours}>{arrowDown()}</button>
                    </div>
                </div>
                <div className='task-edit-overlay-duration-custom-time-picker task-edit-overlay-duration-custom-time-picker--minutes'>
                    <span className='task-edit-overlay-duration-custom-time-picker-value' onClick={() => handleOpenDropDown(selectMinutesRef)}>{selectedMinutes} {selectedMinutes === 1 ? 'Minute' : 'Minutes'}</span>
                    {isMobile && <select value={selectedMinutes} 
                                         onChange={handleChangeMinutes} 
                                         className='hidden-select-element' 
                                         name="duration-selector" 
                                         ref={selectMinutesRef} 
                                         onFocus={e => { e.currentTarget.size = 6; e.currentTarget.style.zIndex = "1" }} 
                                         onBlur={e => { e.currentTarget.size = 0; e.currentTarget.style.zIndex = "-1" }}>
                        {Array.from(Array(60).keys()).map(minute => <option key={minute} value={minute}>{minute}</option>)}
                    </select>}
                    <div className='task-edit-overlay-duration-picker-action-buttons-container'>
                        <button className='no-style-button task-edit-overlay-duration-picker-action-button' onClick={incrementMinutes}>{arrowUp()}</button>
                        <button className='no-style-button task-edit-overlay-duration-picker-action-button' onClick={decrementMinutes}>{arrowDown()}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};