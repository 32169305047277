import { apiServiceCSharp, createApiThunk } from "../../shared/api/axios";
import { APIRequestState, IAPIRequestState } from "../../shared/api/models";
import { ApiDataStateType, createAPIReducerCases } from "../../shared/store/utils";
import { createReducer } from '@reduxjs/toolkit';
import { ILoginReqPayload, ILoginResponse, IRegisterPayload, IRegisterPhoneNumberRequestPayload, IUser } from "./auth.interfaces";
import { API_ROUTES } from "../constants";

export interface IAuthState extends ApiDataStateType {
  loginRes: IAPIRequestState<ILoginResponse>;
  registrationRes: IAPIRequestState<IUser>;
  updatePhoneNumberRes: IAPIRequestState<IUser>;
}

const initialStateAuthStore: IAuthState = {
  loginRes: APIRequestState.create(),
  registrationRes: APIRequestState.create(),
  updatePhoneNumberRes: APIRequestState.create(),
};

const createReducerKey = (subKey: string): string => {
  return 'auth/' + subKey;
};

export const loginReqAction = createApiThunk(createReducerKey('loginReqAction'), (req?: { payload: ILoginReqPayload, loginType: string, signal?: AbortSignal }) =>
  apiServiceCSharp.post<ILoginResponse>(API_ROUTES.AUTH.LOGIN + req?.loginType, req?.payload, { signal: req?.signal })
);

export const registrationReqAction = createApiThunk(createReducerKey('registrationReqAction'), (payload?: IRegisterPayload) =>
  apiServiceCSharp.post<IUser>(API_ROUTES.AUTH.REGISTER, payload)
);

export const updatePhoneNumberReqAction = createApiThunk(createReducerKey('updatePhoneNumberReqAction'), (payload?: IRegisterPhoneNumberRequestPayload) =>
  apiServiceCSharp.patch<IUser>(API_ROUTES.USER.UPDATE_USER_INFO, payload)
);

export const authReducer = createReducer(initialStateAuthStore, (builder) => {
  createAPIReducerCases(loginReqAction, 'loginRes', builder);
  createAPIReducerCases(registrationReqAction, 'registrationRes', builder);
  createAPIReducerCases(updatePhoneNumberReqAction, 'updatePhoneNumberRes', builder);
});
