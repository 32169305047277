import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../app/store';
import { resetUserProfileRequest } from '../../settingsMenu.store';
import { ConfirmModal } from '../../../../confirm-modal/ConfirmModal';
import { useApiData } from '../../../../../hooks/useApiData';
import { isErrorCodeNotForbiddenOrUnauthorized } from '../../../../../utils/isErrorCodeNotForbiddenOrUnauthorized';
import { ErrorMessage } from '../../error-message/ErrorMessage';
import { LottieAppLoader } from '../../../../lottie-loader/LottieLoader';
import { EAPIStatus } from '../../../../../api/models';

export interface IProfileForm {
  phoneNumber: string;
  userTextNotificationsEnabled: boolean;
  editPhone: boolean;
}

export const ResetProfile = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [shouldOpenConfirmReset, setShouldOpenConfirmReset] = useState(false);
  const [shouldDisplayError, setShouldDisplayError] = useState(false);
  const { resetUserProfile } = useAppSelector(store => store.settingsMenuReducer);

  useApiData(resetUserProfile, {
    onRejected(error) {
      if (isErrorCodeNotForbiddenOrUnauthorized(error.code)) setShouldDisplayError(true);
    }
  })

  return (
    <Fragment>
      <section className='data'>
        <div>
          <h3 className='title static-string'>{t("settingsPersonalInfoDataTitle")}</h3>
          <p>{t("settingsPersonalInfoDataSubTitle")}</p>
        </div>
        <button className='personal-info-btn' data-testid="reset-profile" onClick={() => setShouldOpenConfirmReset(true)}>{t("settingsPersonalInfoResetProfileButton")}</button>
        { shouldDisplayError && <ErrorMessage /> }
      </section>
      {shouldOpenConfirmReset &&
        <ConfirmModal
          title={t("settingsResetProfileConfirmModalTitleText")}
          informationText={t("settingsResetProfileConfirmModalSubTitleText")}
          onCancel={() => setShouldOpenConfirmReset(false)}
          onConfirm={() => {setShouldOpenConfirmReset(false); dispatch(resetUserProfileRequest())}}
          confirmBtnText={t("settingsResetProfileConfirmModalConfirmButtonText")}
        />
      }
      {resetUserProfile.status === EAPIStatus.PENDING && <LottieAppLoader testId='reset-profile-loader' className='reset-profile-loader' />}
    </Fragment>
  )
}