import React, {FunctionComponent,ReactNode} from 'react';
import expand from '../../../../../assets/images/expand.svg';
import { useAppDispatch } from '../../../../../app/store';
import { setShouldStageExpand } from '../stage-tasks/stageTasks.store';
import './StageHeader.scss';
 
export const StageHeader:FunctionComponent<{children?: ReactNode|ReactNode[]}>= ({children}) => {
  const dispatch = useAppDispatch();

  return (
    <div className='stage-header'>
      <button type="button" className='expand' onClick={() => dispatch(setShouldStageExpand(true))}><img src={expand} alt="expand" /></button>
      <div className="stage-header-content">
      {children}
      </div>
    </div>
  )
};