import { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import welcomeScreen4Image from '../../../../assets/images/welcome-screen-4-image.png';
import { setIsComeFromRegistration } from '../../../../shared/store/shared.store';
import { useAppDispatch } from '../../../../app/store';
import './CarouselScreen4.scss';

interface ICarouselScreen4Props {
  setIsWelcomeCarouselOpen: Dispatch<SetStateAction<boolean>>;
  isTheLastElementDisplayOnTheScreen: boolean;
}

export const CarouselScreen4: FunctionComponent<ICarouselScreen4Props> = ({ setIsWelcomeCarouselOpen, isTheLastElementDisplayOnTheScreen }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onCloseCarousel = () => {
    dispatch(setIsComeFromRegistration(false));
    setIsWelcomeCarouselOpen(false);
  }

  return (
    <div className="carousel-element welcome-screen-4" data-testid="carousel-element">
      <section>
        <h2 className='title'>{t('welcomeScreen4Title')}</h2>
        <div className="image-container"><img src={welcomeScreen4Image} alt={t("welcomeScreen4ImageAltText4")} /></div>
        <p className='extension-text'> {t("welcomeScreen4Paragraph1")}</p>
        <p className="extension-text section-2">{t("welcomeScreen4Paragraph2")}</p>
      </section>
      <section className='button-container'>
        <button tabIndex={isTheLastElementDisplayOnTheScreen ? 0 : -1} type="button" className='lets-go' data-testid={"lets-go-button"} onClick={onCloseCarousel}>{t('welcomeScreen4Button')}</button>
      </section>
    </div>
  )
}