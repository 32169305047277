import { useCallback, useEffect, useRef } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { createCssVhVariableByViewportHeight } from "../../utils/createCssVhVariableByViewportHeight";

const AppEventListenerHandler = () => {
  const location = useLocation();
  const params = useParams();
  const [searchParams,] = useSearchParams();
  const scrollTopFocusTimer = useRef<NodeJS.Timeout | null>(null);
  const scrollTopTouchendTimer = useRef<NodeJS.Timeout | null>(null);

  // 2 different function each event for different timers -> cant pass the timer as an argument because that we should to pass the reference of the function and cant pass an argument
  const windowScrollTopFocus = useCallback(() => {
    if (scrollTopFocusTimer.current) clearTimeout(scrollTopFocusTimer.current);
    scrollTopFocusTimer.current = setTimeout(() => {
      window.scrollTo({
        top: 0,
      });
    }, 300)
  }, [])

  const windowScrollTopTouchend = useCallback(() => {
    if (scrollTopTouchendTimer.current) clearTimeout(scrollTopTouchendTimer.current);
    scrollTopTouchendTimer.current = setTimeout(() => {
      window.scrollTo({
        top: 0,
      });
    }, 300)
  }, [])

  // when the virtual keyboard is opened scroll to top the window
  const addEventFocusOnTextBox = useCallback((textBoxArr: (HTMLInputElement | HTMLTextAreaElement)[]) => {
    textBoxArr.forEach((item) => {
      item.addEventListener('focus', windowScrollTopFocus);
    })
  }, [windowScrollTopFocus]);

  const removeEventFocusFromTextBox = useCallback((textBoxArr: (HTMLInputElement | HTMLTextAreaElement)[]) => {
    textBoxArr.forEach((item) => {
      item.removeEventListener('focus', windowScrollTopFocus);
    })
  }, [windowScrollTopFocus]);

  useEffect(() => {
    const keyboardTriggeredArr = document.querySelectorAll(`input[type=text], input[type=number], textarea`) as unknown as (HTMLInputElement | HTMLTextAreaElement)[];
    addEventFocusOnTextBox(keyboardTriggeredArr);

    document.addEventListener('touchend', windowScrollTopTouchend);

    return () => {
      if (scrollTopFocusTimer.current) clearTimeout(scrollTopFocusTimer.current);
      if (scrollTopTouchendTimer.current) clearTimeout(scrollTopTouchendTimer.current);
      removeEventFocusFromTextBox(keyboardTriggeredArr);
      document.removeEventListener('touchend', windowScrollTopTouchend);
    }

  }, [addEventFocusOnTextBox, location, params, removeEventFocusFromTextBox, searchParams, windowScrollTopTouchend])

  useEffect(() => {
    createCssVhVariableByViewportHeight();
    addWindowResizeEvent();
    return () => removeWindowResizeEvent();
  }, []);

  const addWindowResizeEvent = () => {
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', createCssVhVariableByViewportHeight);
    } else {
      window.addEventListener('resize', createCssVhVariableByViewportHeight);
    }
  };

  const removeWindowResizeEvent = () => {
    if (window.visualViewport) {
      window.visualViewport.removeEventListener('resize', createCssVhVariableByViewportHeight);
    } else {
      window.removeEventListener('resize', createCssVhVariableByViewportHeight);
    }
  };

  return <></>;
}
export default AppEventListenerHandler;