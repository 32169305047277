import { createAction, createReducer } from "@reduxjs/toolkit";
import { ApiDataStateType, createAPIReducerCases } from "../../../../../shared/store/utils";
import { apiServiceCSharp, createApiThunk } from "../../../../../shared/api/axios";
import { ETasksGroupsType, ICreateTaskReqPayload, IMessageDataTask, IStageTasksListResponse, ITaskUpdateReqPayload } from "./stageTasks.interface";
import { API_ROUTES } from "../../../../../app/constants";
import { APIRequestState, IAPIRequestState } from "../../../../../shared/api/models";
import { store } from "../../../../../app/store";
import { orderTasksListByGroups } from "../../../chat/chat-conversation/message-data-card/tasks-list/TasksList.utils";


export interface IStageTasksStore extends ApiDataStateType {
  tasksListResponse: IAPIRequestState<IStageTasksListResponse>,
  tasksFilter: ETasksGroupsType,
  updateTaskRes: IAPIRequestState<IMessageDataTask>,
  shouldStageExpand: boolean,
  selectedTaskForEditing: IMessageDataTask | null,
  shouldOpenAddEditTaskFrom: boolean
  createTaskRes: IAPIRequestState<IMessageDataTask[]>,
  tasksOrderedByGroups: {[key: string]: IMessageDataTask[]} | null;
}

const initialStateStageTasksStore: IStageTasksStore = {
  tasksListResponse: APIRequestState.create(),
  tasksFilter: ETasksGroupsType.MY_TASKS,
  updateTaskRes: APIRequestState.create(),
  shouldStageExpand: false,
  selectedTaskForEditing: null,
  shouldOpenAddEditTaskFrom: false,
  createTaskRes: APIRequestState.create(),
  tasksOrderedByGroups: null
};

const createReducerKey = (subKey: string): string => {
  return 'stageTasks/' + subKey;
};

export const getTasksListReqAction = createApiThunk(createReducerKey('getTasksListReqAction'), () =>
  apiServiceCSharp.get<IStageTasksListResponse>(`${API_ROUTES.TASKS.TASKS_LIST}?timestamp=${store.getState().StageTasksReducer.tasksListResponse?.data?.timestampForDelta || 0}`),
);

export const updateTaskReqAction = createApiThunk(createReducerKey('updateTaskReqAction'), (reqPayload?:ITaskUpdateReqPayload) =>
  apiServiceCSharp.patch<IMessageDataTask>(`${API_ROUTES.TASKS.UPDATE_TASK}`,reqPayload),
);

export const createTaskReqAction = createApiThunk(createReducerKey('createTaskReqAction'), (reqPayload?:ICreateTaskReqPayload) =>
  apiServiceCSharp.post<IMessageDataTask[]>(`${API_ROUTES.TASKS.CREATE_TASK}`,reqPayload),
);

export const setTasksFilter = createAction<ETasksGroupsType>(createReducerKey('setTasksFilter'));

export const setShouldStageExpand = createAction<boolean>(createReducerKey('setShouldStageExpand'));

export const setSelectedTaskForEditing = createAction<IMessageDataTask | null>(createReducerKey('setSelectedTaskForEditing'));

export const setShouldOpenAddEditTaskFrom = createAction<boolean>(createReducerKey('setShouldOpenAddEditTaskFrom'));

export const StageTasksReducer = createReducer(initialStateStageTasksStore, (builder) => {
  createAPIReducerCases(getTasksListReqAction, 'tasksListResponse', builder, {
    onFulfilled(state, tasksData) {
      // use new Map and .map() to merge the existing arrays with the newest without including duplicate items, based on the id property
      const mergedWithoutDuplicatesTasksArr =  Array.from(new Map((Object.values(state.tasksOrderedByGroups||{}).flat() as IMessageDataTask[]).concat(tasksData.tasks).map((item:IMessageDataTask)  => [item.id, item])).values())
      const tasksOrderedByGroups = orderTasksListByGroups(mergedWithoutDuplicatesTasksArr);
      state.tasksOrderedByGroups = {...tasksOrderedByGroups}
    },
  });

  createAPIReducerCases(updateTaskReqAction, 'updateTaskRes', builder);
  
  createAPIReducerCases(createTaskReqAction, 'createTaskRes', builder);

  builder.addCase(setTasksFilter, (state, action) => {
    state.tasksFilter = action.payload;
  });

  builder.addCase(setShouldStageExpand, (state, action) => {
    state.shouldStageExpand = action.payload;
  });
  
  builder.addCase(setSelectedTaskForEditing, (state, action) => {
    state.selectedTaskForEditing = action.payload;
  });

  builder.addCase(setShouldOpenAddEditTaskFrom, (state, action) => {
    state.shouldOpenAddEditTaskFrom = action.payload;
  });
});

