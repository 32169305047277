import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './ConfirmModal.scss';

export interface IConfirmModalProps {
  title: string;
  informationText?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  confirmBtnText?: string;
}

export const ConfirmModal: FunctionComponent<IConfirmModalProps> = ({ title, informationText, onCancel, onConfirm, confirmBtnText }) => {
  const { t } = useTranslation();

  // close the modal when clicking on the 'Escape' keyboard key
  const handleKeyup = useCallback(
    (e: unknown): void => {
      if ((e as React.KeyboardEvent).code === 'Escape' && !!onCancel) onCancel();
    },
    [onCancel],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyup);

    return () => {
      document.removeEventListener('keydown', handleKeyup);
    };
  }, [handleKeyup]);

  return (
    <>
      <div className="confirm-modal-background"></div>
      <div className='confirm-modal fadeIn' data-testid="confirm-modal">
        <div className='confirm-modal-content'>
          <h4 className='confirm-modal-title'>{title}</h4>
          {informationText && <p className='confirm-modal-info'>{informationText}</p>}
        </div>
        <div className="confirm-modal-footer">
          {onCancel && <button className='confirm-modal-cancel' data-testid="cancel-button-modal" onClick={onCancel}>{t('confirmModalCancelButtonText')}</button>}
          {onConfirm && <button className='confirm-modal-confirm' data-testid="confirm-button-modal" onClick={onConfirm}>{confirmBtnText || t('confirmModalConfirmButtonText')}</button>}
        </div>
      </div>
    </>
  )
}