import { InternalAxiosRequestConfig } from 'axios';
import { chatSessionIdSessionStorageKey, loginTypeStorageKey, tokenLocalStorageKey, userInfoLocalStorageKey } from '../../app/constants';
import { ApplicationInsightsApi } from '../../application-insights';
import { getItemFromSessionOrLocalStorage } from '../utils/getItemFromSessionOrLocalStorage';
import { IUser } from '../../app/auth/auth.interfaces';

/**
 * Add auth header with token, depending on server convention you might not need to add the word 'Bearer'
 * @param config axios config
 */
export const addAuthorizationHeaderInterceptor = (config: InternalAxiosRequestConfig) => {
    // get token from local-storage
    try {
        const token = getItemFromSessionOrLocalStorage<string>(tokenLocalStorageKey);
        const loginType = getItemFromSessionOrLocalStorage<string>(loginTypeStorageKey);
        if (config?.headers) {
            // if there is a token saved try to use it and set it in the headers in the request.
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
                config.headers['x-auth-provider'] = loginType;
            }
            config.headers['x-client-version'] = process.env.REACT_APP_BUILD_VERSION;
        }
        return config;
    } catch (error) {
        ApplicationInsightsApi.trackException(error);
        console.error(error);
        return Promise.reject(error);
    }
};

export const addApplicationInsightsTraces = (config: InternalAxiosRequestConfig) => {
    try {
        const eventData = {
            requestBody: {...config?.data} || {},
            requestParams: {...config?.params} || {},
            userId: (getItemFromSessionOrLocalStorage(userInfoLocalStorageKey) as IUser)?.id || "userId_not_available",
            sessionId: getItemFromSessionOrLocalStorage(chatSessionIdSessionStorageKey) || "sessionId_not_available",
            baseURL: config?.baseURL,
        };
        ApplicationInsightsApi.trackEvent(`Client app - ${config?.method} - ${config?.url}`,eventData);
        return config;
    } catch (error) {
        ApplicationInsightsApi.trackException(error);
        return Promise.reject(error);
    }
};

export const rejectionApplicationInsightTraces = (error: any) => {
    try {
        const eventData = {
            error: {...error} || 'request failed',
            userId: (getItemFromSessionOrLocalStorage(userInfoLocalStorageKey) as IUser)?.id || "userId_not_available",
            sessionId: getItemFromSessionOrLocalStorage(chatSessionIdSessionStorageKey) || "sessionId_not_available",
        };
        ApplicationInsightsApi.trackEvent(`Client app error`,eventData);
        return Promise.reject(error);
    } catch (error) {
        ApplicationInsightsApi.trackException(error);
        return Promise.reject(error);
    }
};