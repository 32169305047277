import { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { inviteFriendsLink, userInfoLocalStorageKey } from '../../../../../../app/constants';
import { useLocalStorage } from '../../../../../utils/useLocalStorage';
import { IUser } from '../../../../../../app/auth/auth.interfaces';
import './InviteFriends.scss';

interface IInviteFriendsProps{
  setShouldDisplayShareLink: Dispatch<SetStateAction<boolean>>
}

export const InviteFriends:FunctionComponent<IInviteFriendsProps> = ({setShouldDisplayShareLink}) => {
  const {t} = useTranslation();
  const [userInfo,] = useLocalStorage(userInfoLocalStorageKey, '');

  const inviteFriendsShareText = ():string => {
    const userName = !!(userInfo as IUser).name ? " "+(userInfo as IUser).name : '';
    return t("settingsInviteFriendsShareText").replace('{user_name}',userName);
  }

  // if shared failed (catch) - it mean there is no support on share() -> open the copy link component
  const onInviteFriends = async() => {
    try {
      const shareData = {
        title: "",
        text: inviteFriendsShareText(),
        url: inviteFriendsLink((userInfo as IUser)?.id),
      };
      await navigator.share(shareData);
    } catch (err) {
      setShouldDisplayShareLink(true);
    }
  }

  return (
    <div className='invite-friends-flex'>
      <h3 className='static-string'>{t("settingsInviteFriendsTitle")}</h3>
      <div className="invite-friends-text text">{t("settingsInviteFriendsParagraph")}</div>
      <button className='invite-friends-button' onClick={onInviteFriends}>{t("settingsInviteFriendsButtonText")}</button>
    </div>
  )
}