import { EChatMessageDataType, IChatMessage, IChatMessageData, IListMessageDataRes, IMessageResponse, TChatMessageType } from "../chat.interfaces";
import { orderTasksListByGroups } from "./chat-conversation/message-data-card/tasks-list/TasksList.utils";

export const getMessageTime = (message: IMessageResponse | IChatMessage) => {
  return new Date(message.creationTime).getTime();
}

export const transformResponseMessageToChatMessage = (messages: IMessageResponse[] = [], lastMessageTimestamp: number | null, messageType: 'history' | 'chatField' = 'history'): IChatMessage[] => {
  try {
      return messages.map((item, index) => {
          return {
              msgId: item.id,
              party: item.party ? item.party as TChatMessageType : 'Bot',
              msg: item.text || "",
              messageTime: getMessageTime(item),
              shouldDisplayTime: messageType === 'chatField' ? shouldDisplayTimeOnChatMessages(index, item, lastMessageTimestamp, 'chatField') : shouldDisplayTimeOnChatMessages(index, item, getPreviousUserOrBotMessageTimestamp(messages, index), 'history'),
              msgSequenceNumber: item.messageSequenceNumber,
              creationTime: item?.creationTime,
              sessionId: item.sessionId,
              debugInfo: item.debugInfo,
              // Incorporate the grouping logic for message data if present
              data: item?.data ? groupMessageDataTasksByDate(item.data as IListMessageDataRes) : null
          } as IChatMessage;
      });
  }
  catch {
      return [];
  }
};

const shouldDisplayTimeOnChatMessages = (index: number, messageItem: IMessageResponse, prevMessageTimestamp: number | null, messageType: 'history' | 'chatField') => {
  // If it's a chatField messages array (botResponse array, or createSession messages array), all these messages have the same creationTime. Therefore, display the message time only for the first one and set it to false for all the rest (when index !== 0)
  if (messageType === 'chatField' && index !== 0) return false;
  return shouldDisplayTime(getMessageTime(messageItem), prevMessageTimestamp);
}

export const shouldDisplayTime = (MessageTimestamp: number, prevMessageTimestamp: number | null) => {
  const elapsedTimeToDisplayDateTimeInMinutes = 15;
  if (prevMessageTimestamp) {
      // convert the number of milliseconds that have passed to minutes and check if it is greater than the elapsedTimeToDisplayDateTimeInMinutes var
      return ((MessageTimestamp - prevMessageTimestamp) / 60000) > elapsedTimeToDisplayDateTimeInMinutes;
  }
  return true;
}

export const getPreviousUserOrBotMessageTimestamp = (messagesArr: IMessageResponse[] | IChatMessage[], currentIndex: number): number | null => {
  for (let i = currentIndex - 1; i >= 0; i--) {
      if (messagesArr[i].party === 'Bot' || messagesArr[i].party === 'User') return getMessageTime(messagesArr[i]);
  }
  return null;
}

export const groupMessageDataTasksByDate = (msgData: IListMessageDataRes): IChatMessageData => {
  if (msgData.type === EChatMessageDataType.TASKS_LIST && msgData?.content) {
      return {
          ...msgData,
          content: orderTasksListByGroups(msgData.content)
      }
  }
  return msgData as IChatMessageData;
}