import React, { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form';
import EditTaskNotesModal from './EditTaskNotesModal';
import { IAddEditTaskForm, ITaskFields } from '../../stageTasks.interface';
import { useTranslation } from 'react-i18next';
import { isMobileView, onResizeTextareaHeightByTheContext } from '../../../../../../../shared/utils/utils';

interface ITaskNotesProps {
  onUpdateTaskTextarea: (updatedField: ITaskFields, shouldSendMsgToTheBot?: boolean) => void;
}

const TaskNotes = ({ onUpdateTaskTextarea }: ITaskNotesProps) => {
  const [shouldOpenEditNotesModal, setShouldOpenEditNotesModal] = useState(false);
  const taskForm = useFormContext<IAddEditTaskForm>();
  const notesWatch = taskForm.watch("notes");
  const notesRef = useRef<HTMLTextAreaElement | null>(null);
  const { t } = useTranslation();

  const onCloseNotesEditModal = () => {
    onUpdateTaskTextarea({ notes: notesWatch }, false);
    setShouldOpenEditNotesModal(false);
  }

  useEffect(() => {
    onResizeTextareaHeightByTheContext(notesRef.current);
  }, [notesRef, notesWatch]);

  return (
    <>
      <textarea
        {...taskForm.register("notes", { required: false, minLength: 1, onBlur: () => { onUpdateTaskTextarea({ notes: notesWatch }, false) } })}
        className='task-textarea task-notes'
        placeholder={t("addEditTaskNotesPlaceholder")}
        onClick={() => isMobileView() && setShouldOpenEditNotesModal(true)}
        ref={(e) => {
          // The register ref goes here
          taskForm.register("notes").ref(e);
          // Assign the custom ref
          notesRef.current = e;
        }}
      />
      {shouldOpenEditNotesModal &&
        <EditTaskNotesModal onCloseModal={onCloseNotesEditModal} />
      }
    </>
  )
}

export default TaskNotes
