import { useTranslation } from 'react-i18next';
import { ELoginType } from '../../../../../../app/auth/auth.interfaces';
import { API_ROUTES } from '../../../../../../app/constants';
import GoogleLogo from '../../../../../../assets/images//Google-logo.png';

export const baseUrl = window.location.origin;
export const CalendarSyncPopover = () => {
    const { t } = useTranslation();

    const authRedirect = (provider: ELoginType) => {
        window.location.href = `${process.env.REACT_APP_BASE_URL_CSHARP}/${API_ROUTES.AUTH.REDIRECT}/${provider}?redirectUri=${process.env.REACT_APP_BASE_URL_CSHARP}/${API_ROUTES.AUTH.CALENDAR_LOGIN}&isCalendarLogin=true&state=${baseUrl}/app/chat?settingsMenu=open`;
    };

    return (
        <div className="calendar-sync-popover-container">
            <h4 className="calendar-sync-popover-title">Select calendar service</h4>
            <div className="calendar-sync-popover-buttons">
                <button className="calendar-sync-popover-google-button" onClick={() => authRedirect(ELoginType.GOOGLE)}>
                    <img src={GoogleLogo} alt={t('loginGoogleLogoImageAltText')} />
                    {t('syncWithGoogle')}
                </button>
            </div>
        </div>
    );
};
