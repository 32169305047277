import { useCallback, FunctionComponent, useRef } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useLocalStorage } from '../../../shared/utils/useLocalStorage';
import { IChatForm, IChatMessage } from '../chat.interfaces';
import { chatSessionIdSessionStorageKey, dislikeFeedbackParam, messagesAmountToDisplayFeedbackModal, messagesCounterStorageKey, settingsMenuParam, userInfoLocalStorageKey } from '../../../app/constants';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { setPrepopulatedOptions, setSelectedOptionIndex } from '../chat.store';
import { useApiData } from '../../../shared/hooks/useApiData';
import { EAPIStatus, IAPIError } from '../../../shared/api/models';
import { FORBIDDEN, UNAUTHORIZED } from '../../../shared/api/axios';
import { ContentFrameWrapper } from '../../../shared/components/content-frame-wrapper/ContentFrameWrapper';
import { LottieAppLoader } from '../../../shared/components/lottie-loader/LottieLoader';
import { ChatConversation } from './chat-conversation/ChatConversation';
import { isMobileView } from '../../../shared/utils/utils';
import { SessionSummariesWrapper } from './chat-history/SessionSummariesWrapper';
import { isDebugModeAllowed } from '../../../shared/utils/isDebugModeAllowed';
import { setAppModalContentType } from '../../../shared/components/app-modals/appModals.store';
import { EAppModalContentType } from '../../../shared/components/app-modals/appModals.interfaces';
import { getItemFromSessionOrLocalStorage } from '../../../shared/utils/getItemFromSessionOrLocalStorage';
import { StageContainer } from '../resizable-container/stage-container/StageContainer';
import { ChatFormUserInput } from './chat-form-user-input/ChatFormUserInput';
import { getTasksListReqAction } from '../resizable-container/stage-container/stage-tasks/stageTasks.store';
import { getPreviousUserOrBotMessageTimestamp, shouldDisplayTime, transformResponseMessageToChatMessage } from './Chat.utils';
import './Chat.scss';

export const Chat: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { botResponse, sessionId, selectedOptionIndex, prepopulatedOptions } = useAppSelector(store => store.chatReducer);
  const { updateUserProfile } = useAppSelector(store => store.settingsMenuReducer);
  const [sessionIdLocalStorage, setSessionIdLocalStorage] = useLocalStorage(chatSessionIdSessionStorageKey, '');
  const [userInfoLocalStorage,] = useLocalStorage(userInfoLocalStorageKey, '');
  const [, setMessageCounterLocalStorage] = useLocalStorage(messagesCounterStorageKey, '');
  const messagesCounterRef = useRef<number>(Number(getItemFromSessionOrLocalStorage(messagesCounterStorageKey)||0));
  const { shouldStageExpand } = useAppSelector(store => store.StageTasksReducer);

  const chatForm = useForm<IChatForm>({
    defaultValues: {
      userMessage: "",
      messagesArr: [],
      isRecording: false,
      shouldDisplayRecordingErrorMsg: false
    }
  });

  const { fields, append, remove } = useFieldArray({
    control: chatForm.control,
    name: 'messagesArr',
  });

  // focusing the student's input just if there is no open popups within the chat
  const focusStudentInput = useCallback(() => {
    const params = new URLSearchParams(document.location.search);
    if (!!!params.get(settingsMenuParam) && !!!params.get(dislikeFeedbackParam)) {
      setTimeout(() => chatForm.setFocus('userMessage'),0);
    };
  }, [chatForm]);

  const appendNewMessage = useCallback((messages: IChatMessage[], shouldResetOrFocusInputField?: 'reset' | 'focus') => {
    append(messages);
    if (shouldResetOrFocusInputField === 'reset') chatForm.resetField('userMessage');
    else if (shouldResetOrFocusInputField === 'focus') focusStudentInput();
  }, [append, chatForm, focusStudentInput])


  // set user text-box with the user message, and remove the user message from the list.
  const removeLastUserMessageFromChatWindow = () => {
    if (fields.length > 0 && fields[fields.length - 1].party === 'User') {
      chatForm.setValue('userMessage', fields[fields.length - 1].msg);
      focusStudentInput();
      remove(fields.length - 1);
    }
  }

  useApiData(botResponse, {
    // if call HumanStudentTurnSendInput API success -> save server response and display it to the user student
    onFulfilled(botResponseData) {
      if (selectedOptionIndex !== null) {
        const userMessage: IChatMessage = {
          party: 'User',
          msg: prepopulatedOptions ? prepopulatedOptions[selectedOptionIndex]?.text : '',
          messageTime: Date.now(),
          creationTime: new Date().toISOString(),
          shouldDisplayTime: shouldDisplayTime(Date.now(), fields.length > 0 ? fields[fields.length - 1].messageTime : null),
          sessionId: sessionId?.data?.sessionId || sessionIdLocalStorage
        }
        // append the selected option as user message
        append(userMessage);
        dispatch(setSelectedOptionIndex(null));
      }

      if (botResponseData && botResponseData.length > 0) {
        // update stage area tasks list when botResponse include 'data' property
        if(botResponseData[botResponseData.length - 1].data) dispatch(getTasksListReqAction());

        let newMessages: IChatMessage[] = [];
        const lastResponseMessage = botResponseData[botResponseData.length - 1];
        // Find the last message's timestamp
        const lastFieldsMessageTimestamp = getPreviousUserOrBotMessageTimestamp(fields, fields.length);
        if (isDebugModeAllowed()) {
          newMessages = transformResponseMessageToChatMessage(botResponseData.filter(item => item.party !== 'User'), lastFieldsMessageTimestamp, 'chatField');
        }
        else {
          const messages = transformResponseMessageToChatMessage([lastResponseMessage], lastFieldsMessageTimestamp, 'chatField');
          if (messages.length > 0) newMessages.push(messages[0]);
        }

        // Append all messages at once 
        //and add focus to the user text-box input after the bot responded
        appendNewMessage(newMessages, 'focus');

        // After processing all messages, check if the last message has options
        dispatch(setPrepopulatedOptions(lastResponseMessage?.options || null));

        // for opening the app feedback modal when needed
        // TODO: Grouping all the actions related to the user to the user.store and use one true redux user state instead of localStorage
        const shouldDisplayFeedbackSurvey = !!updateUserProfile?.data ? updateUserProfile.data.shouldDisplayFeedbackSurvey : userInfoLocalStorage?.shouldDisplayFeedbackSurvey;
        if (shouldDisplayFeedbackSurvey && messagesCounterRef.current < messagesAmountToDisplayFeedbackModal) {
          const messageCounter = messagesCounterRef.current + 1
          setMessageCounterLocalStorage(messageCounter);
          messagesCounterRef.current = messageCounter;
          if (messageCounter === messagesAmountToDisplayFeedbackModal) {
            dispatch(setAppModalContentType(EAppModalContentType.FEEDBACK));
            setMessageCounterLocalStorage(0);
            messagesCounterRef.current = 0;
          }
        }
      }
    },
    onRejected(error: IAPIError) {
      if (error.code !== FORBIDDEN && error.code !== UNAUTHORIZED) {
        removeLastUserMessageFromChatWindow();
        dispatch(setSelectedOptionIndex(null));
      }
    }
  })

  useApiData(sessionId, {
    onFulfilled: (createSessionResponse) => {
      setSessionIdLocalStorage(createSessionResponse.sessionId);
      if (createSessionResponse?.messages && createSessionResponse?.messages[createSessionResponse?.messages.length - 1].text) {
        let newMessages: IChatMessage[] = [];
        const lastResponseMessage = createSessionResponse?.messages[createSessionResponse?.messages.length - 1];
        if (isDebugModeAllowed()) {
          newMessages = transformResponseMessageToChatMessage(createSessionResponse?.messages, null, 'chatField');
        }
        else {
          const messages = transformResponseMessageToChatMessage([lastResponseMessage], null, 'chatField');
          if (messages.length > 0) newMessages.push(messages[0]);
        }

        // Append all messages at once 
        //and add focus to the user text-box input after the bot responded
        appendNewMessage(newMessages, 'focus');
        dispatch(setPrepopulatedOptions(lastResponseMessage?.options || null));
      }
      // if there is no bot greeting - insert all the messages that belong to the current session in to the fields array
      else if (createSessionResponse.history.length > 0) {
        appendNewMessage(transformResponseMessageToChatMessage(createSessionResponse.history.filter(historyItem => historyItem.sessionId === createSessionResponse.sessionId), null), 'focus');

      }
    }
  })



  return (
    <div className={`chat-container fadeIn`} data-testid="chat-window-container" id='chat-window-container'>
      {isDebugModeAllowed() && <div className='debug-user-card'>userId: {userInfoLocalStorage?.id},<br /><br />sessionId: {sessionIdLocalStorage}</div>}
      <ContentFrameWrapper shouldDisplayHeader={true} className='chat-frame neutral-4-bg'>
        <FormProvider {...chatForm}>
          <div className="chat">
            {/* if Get sessionId API request that called in the background is in pending show loader, else show chat conversation */}
            {sessionId.status === EAPIStatus.PENDING
              ? <LottieAppLoader testId='chat-lottie-loader' />
              : <>
                {(isMobileView() || shouldStageExpand) && <StageContainer />}
                <ChatConversation fields={fields} /></>
            }
            <ChatFormUserInput appendNewMessage={appendNewMessage} />
            <SessionSummariesWrapper />
          </div>
        </FormProvider>
      </ContentFrameWrapper>
    </div>
  )
}