import DiscordLink from '../../discord-link/DiscordLink';
import { TopSectionTitle } from './top-section-title/TopSectionTitle';
import { PersonalInfo } from './personal-info/PersonalInfo';
import { SettingsFeedback } from './settings-feedback/SettingsFeedback';
import { ImportantLinks } from './important-links/ImportantLinks';
import { useSearchParams } from 'react-router-dom';
import { settingsMenuParam, userInfoLocalStorageKey } from '../../../../app/constants';
import { InviteFriendsCard } from './invite-friends-card/InviteFriendsCard';
import DownloadSessionButton from './download-session-button/DownloadSessionButton';
import { useLocalStorage } from '../../../utils/useLocalStorage';
import closeIcon from '../../../../assets/images/close-settings.svg';
import './SettingsMenu.scss';

export const SettingsMenu = () => {
  const [searchParams,setSearchParams] = useSearchParams();
  const [userInfo,] = useLocalStorage(userInfoLocalStorageKey, '');

  const onCloseSettings = () => {
    setSearchParams(prev => {
      prev.delete(settingsMenuParam);
      return prev;
    });
  }

  return (
    <>
      {searchParams.get(settingsMenuParam) &&
        <div className={`settings-menu neutral-4-bg fadeIn`} data-testid='settings-menu'>
          <button className='close-settings' type='button' onClick={onCloseSettings}><img src={closeIcon} alt="close-settings" /></button>
          <div className="scrollable-container">
            <TopSectionTitle />
            <PersonalInfo />
            <InviteFriendsCard />
            <SettingsFeedback />
            <DiscordLink className='settings-section-container' />
            {userInfo?.isInternalUser && <DownloadSessionButton />}
            <ImportantLinks />
          </div>
        </div>
      }
    </>
  )
}