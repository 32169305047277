import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { sharedStoreReducer } from '../shared/store/shared.store';
import { chatReducer } from '../features/chat-wrapper/chat.store';
import { authReducer } from './auth/auth.store';
import { settingsMenuReducer } from '../shared/components/content-frame-wrapper/settings-menu/settingsMenu.store';
import { appModalsReducer } from '../shared/components/app-modals/appModals.store';
import { userReducer } from './useAppData/user.store';
import { StageTasksReducer } from '../features/chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.store';

export const rootReducer = {
    sharedStoreReducer,
    chatReducer,
    authReducer,
    settingsMenuReducer,
    userReducer,
    appModalsReducer,
    StageTasksReducer
};

export const store = configureStore({
    reducer: rootReducer
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
