export interface ILoginResponse {
  token: string;
  refreshToken: string;
}

export interface IUser {
  id: string;
  email: string | null;
  name: string | null;
  profileImage: string | null;
  phoneNumber?: string | null;
  userTextNotificationsEnabled: boolean;
  carrierTextNotificationsEnabled: boolean;
  preferredName: string | null;
  isInternalUser?: boolean;
  shouldDisplayFeedbackSurvey: boolean;
  calendarProvider?: string | null;
}

export enum ELoginType {
  GOOGLE = 'google',
  APPLE = 'apple',
  DISCORD = 'discord'
}

export interface ILoginReqPayload {
  accessToken: string;
  redirectUri: string
}

export interface IRegisterPayload {
  registrationCode: string;
}
export class RegisterForm {
  registrationCode = '';
  confirmToUseMax = false;
}
export class AddPhoneRegistrationForm {
  userPhone = '';
}
export interface IRegisterPhoneNumberRequestPayload {
  phoneNumber: string;
}

export interface ICalendarProvider {
  provider: string;
}