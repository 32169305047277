import React, { Dispatch, FunctionComponent, SetStateAction } from 'react'
import QuickOptionsReminder from './quick-options-reminders/QuickOptionsReminder';
import CustomReminder from './custom-reminder/CustomReminder';
import { ITaskEditorOverlayType } from '../TaskEditOverlay';

interface IReminderOverlayProps {
  setReminderOverlayType: Dispatch<SetStateAction<"quickOptions" | "custom" | null>>;
  reminderOverlayType: "quickOptions" | "custom" | null;
  editOverlayType: ITaskEditorOverlayType;
  relativeDate: Date;
  selectedReminder: number | undefined | null;
  setSelectedReminder: Dispatch<SetStateAction<number | null | undefined>>;
  handleSet?: (() => void);
}

const ReminderOverlay: FunctionComponent<IReminderOverlayProps> = ({ setSelectedReminder,selectedReminder,setReminderOverlayType, reminderOverlayType, editOverlayType, relativeDate, handleSet }) => {

  const getReminderComponentByType = () => {
    switch (reminderOverlayType) {
      case "quickOptions":
        return <QuickOptionsReminder handleSet={handleSet} relativeDate={relativeDate} setReminderOverlayType={setReminderOverlayType} editOverlayType={editOverlayType} setSelectedReminder={setSelectedReminder}/>
      case "custom":
        return <CustomReminder  relativeDate={relativeDate} selectedReminder={selectedReminder} setSelectedReminder={setSelectedReminder} editOverlayType={editOverlayType}/>
      default:
        return <></>
    }
  }
  return (
    <div>
      {getReminderComponentByType()}
    </div>
  )
}

export default ReminderOverlay
