import { useTranslation } from 'react-i18next';
import { StageHeader } from '../stage-header/StageHeader';
import { useAppSelector } from '../../../../../app/store';
import TasksGroup from '../../../chat/chat-conversation/message-data-card/tasks-list/TasksGroup';
import { StageTasksDropdownFilters } from './stage-tasks-dropdown-filters/StageTasksDropdownFilters';
import { ETaskStatus, ETasksGroupsType } from './stageTasks.interface';
import { StageTasksEmptyState } from './stage-tasks-emty-state/StageTasksEmptyState';
import Message from '../../../chat/message/Message';
import { EAPIStatus } from '../../../../../shared/api/models';
import ErrorMessage from '../../../chat/message/error-message/ErrorMessage';
import CreateOrEditTask from './create-or-edit-task/CreateOrEditTask';
import { isOverdueDate } from '../../../chat/chat-conversation/message-data-card/tasks-list/TasksList.utils';
import { transformResponseMessageToChatMessage } from '../../../chat/Chat.utils';
import './StageTasks.scss';

export const StageTasks = () => {
  const { tasksOrderedByGroups, tasksFilter, updateTaskRes, tasksListResponse, shouldOpenAddEditTaskFrom } = useAppSelector(store => store.StageTasksReducer);
  const { botResponse } = useAppSelector(store => store.chatReducer);
  const { t } = useTranslation();

  const getGroupsByFilter = () => {
    if (!!tasksOrderedByGroups) {
      switch (tasksFilter) {
        case ETasksGroupsType.MY_TASKS:
          return (
            <>
              <TasksGroup groupTitle={t("stageTaskPriorityGroupTitle")} taskGroupItems={tasksOrderedByGroups[ETasksGroupsType.PRIORITY]} groupType={ETasksGroupsType.PRIORITY} />
              <TasksGroup groupTitle={t("stageTasksOverdueGroupTitle")} taskGroupItems={tasksOrderedByGroups[ETasksGroupsType.OVERDUE]} groupType={ETasksGroupsType.OVERDUE} />
              <TasksGroup groupTitle={t("stageTaskQuickWinsGroupTitle")} taskGroupItems={tasksOrderedByGroups[ETasksGroupsType.QUICK_WINS]} groupType={ETasksGroupsType.QUICK_WINS} />
              <TasksGroup groupTitle={t("stageTasksScheduledGroupTitle")} taskGroupItems={tasksOrderedByGroups[ETasksGroupsType.SCHEDULED]} groupType={ETasksGroupsType.SCHEDULED} />
              <TasksGroup groupTitle={t("stageTaskUnscheduledGroupTitle")} taskGroupItems={tasksOrderedByGroups[ETasksGroupsType.UNSCHEDULED]} groupType={ETasksGroupsType.UNSCHEDULED} />
            </>
          );
        case ETasksGroupsType.OVERDUE:
          return <TasksGroup groupTitle={t("stageTasksOverdueGroupTitle")} groupType={ETasksGroupsType.OVERDUE} taskGroupItems={Object.values(tasksOrderedByGroups||{}).flat().filter(item => item?.dueDate && isOverdueDate(item.dueDate))} />

        case ETasksGroupsType.SCHEDULED:
          return <TasksGroup groupTitle={t("stageTasksScheduledGroupTitle")} groupType={ETasksGroupsType.SCHEDULED} taskGroupItems={tasksOrderedByGroups[ETasksGroupsType.SCHEDULED]} />

        case ETasksGroupsType.UNSCHEDULED:
          return <TasksGroup groupTitle={t("stageTaskUnscheduledGroupTitle")} groupType={ETasksGroupsType.UNSCHEDULED} taskGroupItems={Object.values(tasksOrderedByGroups||{}).flat().filter(item => !item?.workTime && item.status !== ETaskStatus.DONE)} />
        
          case ETasksGroupsType.PRIORITY:
          return <TasksGroup groupTitle={t("stageTaskPriorityGroupTitle")} groupType={ETasksGroupsType.PRIORITY} taskGroupItems={Object.values(tasksOrderedByGroups||{}).flat().filter(item => item.priority)} />

          case ETasksGroupsType.QUICK_WINS:
          return <TasksGroup groupTitle={t("stageTaskQuickWinsGroupTitle")}  groupType={ETasksGroupsType.QUICK_WINS} taskGroupItems={Object.values(tasksOrderedByGroups||{}).flat().filter(item => item.quickWin)} />

        case ETasksGroupsType.DONE:
          return <TasksGroup groupType={ETasksGroupsType.DONE} taskGroupItems={tasksOrderedByGroups[ETasksGroupsType.DONE]} />

        default:
          return <></>
      }
    }
  }

  return (
    <div className='stage-tasks-container'>
      <StageHeader><StageTasksDropdownFilters /></StageHeader>
      <div className="scrollable-container">
        {getGroupsByFilter()}
        {!!botResponse.data?.length && <Message msgItem={transformResponseMessageToChatMessage([botResponse.data[botResponse.data?.length - 1]], null)[0]} hideMsgTime={true} />}
        {!botResponse.data?.length && [updateTaskRes.status, tasksListResponse.status, botResponse.status].includes(EAPIStatus.REJECTED) && <ErrorMessage />}
        <StageTasksEmptyState />
      </div>
      {shouldOpenAddEditTaskFrom && <CreateOrEditTask />}
    </div>
  )
}