import sassVariables from '../../styles/style.module.scss';

export const isMobileView = (): boolean => {
    if (window.visualViewport) return !!(window.visualViewport?.width <= Number(sassVariables.mobileScreenMaxWidth.replace('px', '')));
    return true;
}

export const isDesktopView = (): boolean => {
    if (window.visualViewport) return !!(window.visualViewport?.width >= Number(sassVariables.desktopScreenMinWidth.replace('px', '')));
    return true;
}

export const minutesToHoursAndMinutesStringRepresentation = (minutes: number): string => {
    if (minutes === 1) return '1 minute';
    if (minutes < 60) return `${minutes} minutes`;
    if (minutes === 60) return '1 hour';
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    if (remainingMinutes === 0) return `${hours} hours`;
    if (remainingMinutes === 1) return `${hours} hours and 1 minute`;
    return `${hours} hours and ${remainingMinutes} minutes`;
}

export const onResizeTextareaHeightByTheContext = (element: HTMLElement|null) => {
    if (element) {
        element.style.height = "0px"; // reset before recalculate
        element.style.height = (element.scrollHeight) + "px";
    }
}