import { automaticBotNotificationEvents } from "../../../../../../app/constants";
import { IHumanStudentTurnSendInputPayload } from "../../../../chat.interfaces";
import { ETaskStatus, ETasksGroupsType, IMessageDataTask } from "../../../../resizable-container/stage-container/stage-tasks/stageTasks.interface";

export const unscheduledSorting = (a: IMessageDataTask, b: IMessageDataTask) => {
  // Sorted by due date ascending (for tasks that have a due date) then by creation date, descending (for tasks w/o due date)
  // Tasks with a due date are sorted ascendingly by dueDate
  if (a.dueDate && b.dueDate) {
    return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
  }
  // Tasks without a due date are sorted descendingly by creationDate
  if (!a.dueDate && !b.dueDate) {
    return new Date(b.creationTime).getTime() - new Date(a.creationTime).getTime();
  }
  // A task with a dueDate comes before a task without a dueDate
  if (a.dueDate) return -1;
  if (b.dueDate) return 1;
  return 0;
}

export const getTasksGroupEmoji = (groupType: ETasksGroupsType): string => {
  switch (groupType) {
      case ETasksGroupsType.MY_TASKS:
          return "🙌 ";
      case ETasksGroupsType.OVERDUE:
          return "🚩 ";
      case ETasksGroupsType.UNSCHEDULED:
          return "🗓 ";
      case ETasksGroupsType.DONE:
          return "✔ ";
      case ETasksGroupsType.SCHEDULED:
          return "🚀 ";
      case ETasksGroupsType.PRIORITY:
          return "⭐ ";
      case ETasksGroupsType.QUICK_WINS:
          return "⚡ ";
      default:
          return "";
  }
}

// order tasks by groups shared function
export const orderTasksListByGroups = (tasksList: IMessageDataTask[]): {[key: string]: IMessageDataTask[]} => {
  return tasksList.reduce((accumulator: { [key: string]: IMessageDataTask[] }, current: IMessageDataTask) => {
      let currentGroupName:ETasksGroupsType = ETasksGroupsType.SCHEDULED;
      if (current.status === ETaskStatus.DELETED) return accumulator;
      else if(current.status === ETaskStatus.DONE) currentGroupName = ETasksGroupsType.DONE;
      else if(current.priority) currentGroupName = ETasksGroupsType.PRIORITY;
      else if(current.quickWin && !current.workTime) currentGroupName = ETasksGroupsType.QUICK_WINS;
      else if(current?.dueDate && isOverdueDate(current?.dueDate)) currentGroupName = ETasksGroupsType.OVERDUE;
      else if(!current.workTime) currentGroupName = ETasksGroupsType.UNSCHEDULED;
      accumulator[currentGroupName] ? accumulator[currentGroupName].push(current) : accumulator[currentGroupName] = [current]
      return accumulator;
  }, {[ETasksGroupsType.OVERDUE]: [],[ETasksGroupsType.SCHEDULED]: [], [ETasksGroupsType.UNSCHEDULED]: [], [ETasksGroupsType.DONE]: [],[ETasksGroupsType.QUICK_WINS]: [],[ETasksGroupsType.PRIORITY]: []});
}

export const isOverdueDate = (date: string|Date):boolean => new Date(date) < new Date();

// TODO move to a class handling all automated bot events
export const createAutomatedTaskEvent = (sessionId: string, field: string, value: any, task: IMessageDataTask): IHumanStudentTurnSendInputPayload => {
  return {
      sessionId,
      option: {
          text: "",
          tool: {
            name: automaticBotNotificationEvents.UX_SET_TASK_FIELD,
            arg: {
              field,
              value,
              task
            }
          }
      }
  }
}