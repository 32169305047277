import React from 'react';
import closeIcon from "../../../../../../../assets/images/small-close-icon.svg";
import { ITaskTag } from '../../stageTasks.interface';
import SassVariables from "../../../../../../../styles/style.module.scss";

interface ISingleTagProps {
  tag: ITaskTag;
  index: number;
  onDeleteTag: (index: number) => void;
}

const SingleTag = ({ tag, index, onDeleteTag }: ISingleTagProps) => {

  const taskStyle = {
    backgroundColor: tag.color || 'transparent',
    color: tag.color !== 'transparent' ? 'white' : SassVariables.MaxDarkColor,
    borderColor: tag.color !== 'transparent' ? SassVariables.Neutral1Color : SassVariables.MaxDarkColor
  }

  const closeImgStyle = {
    filter: tag.color !== 'transparent' ? 'none' : 'invert(1)'
  }

  return (
    <div className={`single-tag ${!tag.name ? 'without-name' : ''}`} style={taskStyle}>
      {tag.name}
      <button className='delete-tag' type='button' onClick={() => onDeleteTag(index)}><img src={closeIcon} alt="close" style={closeImgStyle} /></button>
    </div>
  )
}

export default SingleTag
