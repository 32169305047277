import React from 'react';
import './AppOverlayPopover.scss';
import { isMobileView } from '../../utils/utils';
import removeAppOverlayPopover from './removeAppOverlayPopover';

// TODO: handle end of the screen positioning
// TODO: add support for WAI-ARIA attributes
// TODO: add support for animations
// TODO: add support for swipe gestures

interface IAppOverlayPopoverProps {
    content: React.ReactNode;
    event?: React.MouseEvent<HTMLElement> | null;
    className?: string | null;
}

export const AppOverlayPopover: React.FC<IAppOverlayPopoverProps> = ({ event, content, className }) => {

    const getStyle = () => {
        if (event && event!.clientX < 400) {
            return {
                top: event!.clientY,
                left: event!.clientX
            }
        }
        else if (event && event!.clientX > 400) {
            return {
                top: event!.clientY,
                left: event!.clientX - 400
            }
        }
        return { top: "50%", left: "50%", transform: "translate(-50%, -50%)" }
    }

    return (
        <>
            <div
                id='app-overlay-popover-background-container'
                className='app-overlay-popover-background-container'
                onClick={(e) => { e.stopPropagation(); removeAppOverlayPopover() }}>
                <div className={`app-overlay-popover-content-container${className ? ` ${className}` : ''}`}
                    onClick={(e) => { e.stopPropagation(); }}
                    style={!isMobileView() ? getStyle() : {}}>
                    {content}
                </div>
            </div>
        </>
    );
};