import { Fragment } from 'react';
import './TaskOverlayQuickOptions.scss';
import TaskEditOverlayQuickOptionItem from './TaskEditOverlayQuickOptionItem';

export interface ITaskOverlayQuickOptionsProps {
    onSetLater: () => void;
    onSetTomorrow: () => void;
    onSetNextWeekend: () => void;
}

export const TaskOverlayQuickOptions = ({ onSetLater, onSetTomorrow, onSetNextWeekend }: ITaskOverlayQuickOptionsProps) => {

    const quickOption = [
        {
            header: "Later Today",
            date: calculateLaterToday(),
            action: onSetLater
        },
        {
            header: "Tomorrow",
            date: calculateTomorrow(),
            action: onSetTomorrow
        },
        {
            header: "This Weekend",
            date: calculateNextWeekend(),
            action: onSetNextWeekend
        }
    ]

    function calculateLaterToday() {
        const now = new Date();
        const isBefore4pm = now.getHours() < 16;
        if (isBefore4pm) {
            return new Date(now.getFullYear(), now.getMonth(), now.getDate(), 16, 0, 0);
        } else {
            const roundedHour = Math.ceil(now.getHours() + 1);
            return new Date(now.getFullYear(), now.getMonth(), now.getDate(), roundedHour, 0, 0);
        }
    }

    function calculateTomorrow() {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 16, 0, 0);
    }

    function calculateNextWeekend() {
        const now = new Date();
        const upcomingSaturday = new Date();
        upcomingSaturday.setDate(now.getDate() + (6 + (7 - now.getDay())) % 7);
        upcomingSaturday.setHours(9, 0, 0, 0);
        return upcomingSaturday;
    }

    return (
        <div className="task-overlay-quick-options-container">
            {quickOption.map(option => (
                <Fragment key={option.header}>
                    <TaskEditOverlayQuickOptionItem option={option}/>
                </Fragment>
            ))}
            <div className="task-overlay-quick-options-single-option task-overlay-quick-options-custom-option-container">
                <p>or</p>
                <h3>Pick a Date</h3>
                <h3>and Time</h3>
            </div>
        </div>
    );
}