import React, { FunctionComponent } from 'react';
import emptyBell from "../../../../../../../assets/images/task-bell-icon.svg"
import fullBell from "../../../../../../../assets/images/task-full-bell-icon.svg"
import createAppOverlayPopover from '../../../../../../../shared/components/app-overlay-popover/createAppOverlayPopover';
import { TaskEditOverlay } from '../../task-edit-overlays/TaskEditOverlay';
import { useFormContext } from 'react-hook-form';
import { IAddEditTaskForm } from '../../stageTasks.interface';
import './SchedulingSection.scss';

interface ISchedulingSectionProps {
  title: string
  placeholder: string;
  formValue: string;
  icon: string;
  onClick: (e: React.MouseEvent<HTMLElement, any>) => void;
  reminderType?: 'dueDate' | 'workTime';
  reminderValue?: number | null | undefined;
  onUpdateReminderFields?: (reminderFieldName: "dueDate" | "workTime", reminder?: number | null) => void
}

const SchedulingSection: FunctionComponent<ISchedulingSectionProps> = ({ title, formValue, placeholder, icon, onClick, reminderValue, reminderType, onUpdateReminderFields }) => {
  const taskForm = useFormContext<IAddEditTaskForm>();

  const onOpenReminderOverlay = () => {
    if (!!reminderType) {
      return createAppOverlayPopover(
        <TaskEditOverlay
          overlayType={reminderType}
          showOnlyReminderOverlay={!!taskForm.getValues(reminderType)}
          date={taskForm.getValues(reminderType)}
          reminder={reminderValue}
          onChange={(_, reminder) => !!onUpdateReminderFields && onUpdateReminderFields(reminderType, reminder)}
        />)
    }
  }

  return (
    <section onClick={onClick} className='scheduling-section'>
      <h4 className='scheduling-title static-string'>{title}</h4>
      <div className='scheduling-text-wrapper'>
        <div className={`scheduling-text ${formValue ? 'value-exist' : ''}`}>{formValue || <><img className='icon' src={icon} alt='icon' />{placeholder}</>}</div>
        <button
          className={`bell-reminder ${!reminderType && 'visibility-hidden'}`}
          onClick={(e) => {
            e.preventDefault();
            onOpenReminderOverlay();
            e.stopPropagation();
          }}
        >
          <img src={!!reminderValue ? fullBell : emptyBell} alt="bell" />
        </button>
      </div>
    </section>
  )
}

export default SchedulingSection
