import { useTranslation } from 'react-i18next';
import { ContentFrameWrapper } from '../../../shared/components/content-frame-wrapper/ContentFrameWrapper';
import { Avatar } from '../../../shared/components/avatar/Avatar';
import { useLocalStorage } from '../../../shared/utils/useLocalStorage';
import { APP_ROUTING_PATHS, shouldStayLoggedInLocalStorageKey, userInfoLocalStorageKey } from '../../constants';
import { getInitials } from '../../../shared/utils/getInitials';
import { IUser } from '../auth.interfaces';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ApplicationInsightsApi } from '../../../application-insights';
import { moveDataFromSessionToLocalStorage } from '../../../shared/utils/moveDataFromSessionToLocalStorage';
import './StayLoggedIn.scss';
import { handleLogOut } from '../../../shared/utils/logOut';

export const StayLoggedIn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isStayLoggedInChecked, setIsStayLoggedInChecked] = useState(false);
  const [userInfo,] = useLocalStorage(userInfoLocalStorageKey, '');
  const [isLoading, setIsLoading] = useState(false);

  const onClickNext = () => {
    try {
      setIsLoading(true);
      localStorage.setItem(shouldStayLoggedInLocalStorageKey, JSON.stringify(isStayLoggedInChecked))
      if (isStayLoggedInChecked) moveDataFromSessionToLocalStorage();
      navigate(APP_ROUTING_PATHS.REDIRECT);
    }
    catch (error) {
      setIsLoading(false);
      ApplicationInsightsApi.trackException(error);
    }
  }

  return (
    <div className="stay-logged-in-page">
      <ContentFrameWrapper className='stay-logged-in-frame'>
        <h1 data-testid="stay-logged-in-title">{t("stayLoggedInPageTitle")}</h1>
        <Avatar
          size={75}
          avatarImg={userInfo?.profileImage}
          defaultContent={getInitials((userInfo as IUser)?.name || (userInfo as IUser)?.email)}
        />
        <h2 data-testid="user-name">{(userInfo as IUser)?.name ?? ''}</h2>
        <div className='stay-logged-in-email' data-testid="user-email">{(userInfo as IUser)?.email ?? ''}</div>
        <div className="checkbox-container">
          <label>
            <input type="checkbox" name="stay-logged-in-checkbox" data-testid="stay-logged-in-checkbox" onChange={(e) => setIsStayLoggedInChecked(e.target.checked)} />
            {t("stayLoggedInPageCheckboxLabelText")}
          </label>
          <span>{t("stayLoggedInPageCheckboxWarningText")}</span>
        </div>
        <div className="buttons-container">
          <button type='button' className='auth-next-btn' onClick={onClickNext} disabled={isLoading} data-testid="stay-logged-in-next">{t("stayLoggedInPageConfirmButtonText")}</button>
          <Link to={APP_ROUTING_PATHS.LOGIN} onClick={() => handleLogOut()} className='back-link back-link-desktop' data-testid="stay-logged-in-back">{t('registerScreenBackButtonText')}</Link>
        </div>
      </ContentFrameWrapper>
      <Link to={APP_ROUTING_PATHS.LOGIN} onClick={() => handleLogOut()} className='back-link back-link-mobile'>{t('registerScreenBackButtonText')}</Link>
    </div>
  )
}