import dayjs from "dayjs";

export enum EDayjsFormatter {
  FullYear = "YYYY",
  FullMonthWord = "MMMM",
  PartOfMonthWord = "MMM",
  MonthNumberWithZero = "MM",
  MonthNumberWithoutZero = "M",
  DayOfMonthNumberWithoutZero = 'D',
  DayOfMonthNumberWithOrdinalIndicator = 'Do',
  DayOfMonthNumberWithZero = 'DD',
  DayOfWeekNumber = 'd',
  DayOfWeekWord = 'dddd',
  PartOfDayOfWeekWord = 'ddd',
  TimeInAmPm = "h:mmA"
}

export const getTimeByDate = (date: string|number|Date): string => {
  try {
    return dayjs(new Date(date)).format(`${EDayjsFormatter.TimeInAmPm}`)
  } catch (error) {
    return '';
  }
}

export const getDateWithYear = (date: string|number, showOrdinal:boolean=false, showDayOfTheWeek:boolean=true): string => {
  try {
    return dayjs(new Date(date)).format(`${showDayOfTheWeek ? EDayjsFormatter.DayOfWeekWord+', ' : ''}${EDayjsFormatter.FullMonthWord} ${showOrdinal ? EDayjsFormatter.DayOfMonthNumberWithOrdinalIndicator : EDayjsFormatter.DayOfMonthNumberWithoutZero}, ${EDayjsFormatter.FullYear}`)
  } catch (error) {
    return '';
  }
}

export const getDateWithoutYear = (date: string|number|Date, showOrdinal:boolean=false, showShortWords:boolean=false): string => {
  try {
    return dayjs(new Date(date)).format(`${showShortWords ? EDayjsFormatter.PartOfDayOfWeekWord : EDayjsFormatter.DayOfWeekWord+','} ${showShortWords ? EDayjsFormatter.PartOfMonthWord : EDayjsFormatter.FullMonthWord} ${showOrdinal ? EDayjsFormatter.DayOfMonthNumberWithOrdinalIndicator : EDayjsFormatter.DayOfMonthNumberWithoutZero}`)
  } catch (error) {
    return '';
  }
}

export const getDateAndTimeWithoutYear = (date: string|number|Date, showOrdinal:boolean=false, showShortWords:boolean=false): string => {
  try {
    return `${getDateWithoutYear(date,showOrdinal,showShortWords)}, ${getTimeByDate(date)}`;
  } catch (error) {
    return '';
  }
}