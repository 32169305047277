import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from '../../../../../utils/useLocalStorage';
import { IUser } from '../../../../../../app/auth/auth.interfaces';
import { chatSessionIdSessionStorageKey, userInfoLocalStorageKey, userPhoneLength } from '../../../../../../app/constants';
import { useForm } from 'react-hook-form';
import { Switch } from "antd";
import { useAppDispatch, useAppSelector } from '../../../../../../app/store';
import { IUpdatePreferredNameReqPayload } from '../../SettingsMenu.interfaces';
import { updatePreferredNameReq, updateUserProfileReq } from '../../settingsMenu.store';
import { useApiData } from '../../../../../hooks/useApiData';
import { ErrorMessage } from '../../error-message/ErrorMessage';
import { isErrorCodeNotForbiddenOrUnauthorized } from '../../../../../utils/isErrorCodeNotForbiddenOrUnauthorized';
import { EditItemSection } from './EditItemSection';
import { phoneFormatter } from '../../../../../utils/phoneFormatter';

export interface IProfileForm {
  phoneNumber: string,
  preferredName: string;
  userTextNotificationsEnabled: boolean,
  editName: boolean;
  editPhone: boolean,
  shouldDisplayPhoneError: boolean,
  shouldDisplayNotificationError: boolean,
  shouldDisplayNameError: boolean;
}

export const EditProfileForm = () => {
  const { t } = useTranslation();
  const [userInfo, setUserinfo] = useLocalStorage(userInfoLocalStorageKey, '');
  const dispatch = useAppDispatch();
  const { getUserProfile, updateUserProfile, updatePreferredName } = useAppSelector(store => store.settingsMenuReducer);
  const { sessionId } = useAppSelector(store => store.chatReducer);
  const [sessionIdSessionStorage,] = useLocalStorage(chatSessionIdSessionStorageKey, '');
  const focusTimerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (focusTimerRef.current) clearTimeout(focusTimerRef.current);
    }
  }, [])

  // convert +1xxxxxxxxxx to-> xxx xxx xxxx using phoneFormat function above
  const userPhoneFormatter = (phone?: string): string => {
    const sessionStoragePhone = (userInfo as IUser)?.phoneNumber ?? '';
    const phoneNumber = phone ? phone : sessionStoragePhone;
    return phoneFormatter(phoneNumber||'', ' ');
  }

  const form = useForm<IProfileForm>({
    defaultValues: {
      phoneNumber: userPhoneFormatter(),
      userTextNotificationsEnabled: !!(userInfo as IUser)?.userTextNotificationsEnabled,
      preferredName: (userInfo as IUser)?.preferredName ?? '',
      editName: false,
      editPhone: false,
      shouldDisplayPhoneError: false,
      shouldDisplayNotificationError: false,
      shouldDisplayNameError: false
    },
  });

  const watchIsPhoneEditable = form.watch('editPhone');
  const watchIsNameEditable = form.watch('editName');
  const watchShouldDisplayPhoneError = form.watch('shouldDisplayPhoneError');
  const watchShouldDisplayNotificationError = form.watch('shouldDisplayNotificationError');
  const watchShouldDisplayNameError = form.watch('shouldDisplayNameError');

  // Update the form and the sessionStorage with the latest data retrieved from the server using the getUserProfile request
  useApiData(getUserProfile, {
    onFulfilled(data) {
      updateFormDataFromServer(data);
    },
  });

  const onUpdatePhone = () => {
    const reqPayload = { phoneNumber: `+1${form.getValues().phoneNumber.replaceAll(' ', '')}` };
    dispatch(updateUserProfileReq(reqPayload));
  }

  const onUpdateNotificationEnabled = () => {
    const reqPayload = { userTextNotificationsEnabled: form.getValues().userTextNotificationsEnabled };
    dispatch(updateUserProfileReq(reqPayload));
  }

  const onUpdatePreferredName = () => {
    const updateNameReqPayload: IUpdatePreferredNameReqPayload = {
      preferredName: form.getValues().preferredName,
      sessionId: sessionId?.data?.sessionId || sessionIdSessionStorage
    }
    dispatch(updatePreferredNameReq(updateNameReqPayload));
  }

  const apiToFormField: { [key: string]: () => void } = {
    'phoneNumber': onUpdatePhone,
    'preferredName': onUpdatePreferredName
  }

  const onSubmitForm = () => {
    const fields = form.formState.dirtyFields;
    Object.keys(fields).map((k) => apiToFormField[k]());
    form.setValue('editName', false);
    form.setValue('editPhone', false);
    form.reset({}, { keepValues: true });
  }

  const mapForEditItem: { [key: string]: keyof IProfileForm } = {
    'phoneNumber': 'editPhone',
    'preferredName': 'editName'
  }

  const onFocusInput = (name: keyof IProfileForm) => {
    if (watchShouldDisplayPhoneError) form.setValue("shouldDisplayPhoneError", false);
    if (watchShouldDisplayNameError) form.setValue("shouldDisplayNameError", false);
    form.setValue(mapForEditItem[name], true);
    // for awaiting the input displayed on the screen
    if (focusTimerRef.current) clearTimeout(focusTimerRef.current)
    focusTimerRef.current = setTimeout(() => {
      form.setFocus(name);
    }, 0)
  }

  // on change phone input - convert the input value to 'xxx xxx xxxx' format as the input changes.
  const handlePhoneNumberFormatChange = (event: any) => {
    const inputValue = event.target.value;
    form.setValue('phoneNumber', phoneFormatter(inputValue, ' '));
  };

  const onChangeNotificationSwitch = (checked: boolean) => {
    if (watchShouldDisplayNotificationError) form.setValue("shouldDisplayNotificationError", false);
    form.setValue("userTextNotificationsEnabled", checked);
    onUpdateNotificationEnabled();
  }

  useApiData(updateUserProfile, {
    onFulfilled(data) {
      updateFormDataFromServer(data);
    },
    // If update profile rejected -> revert to the previous values and display an error
    onRejected(error) {
      if (isErrorCodeNotForbiddenOrUnauthorized(error.code)) {
        // if rejected and the phone was change revert to the prev phone value and display a phone error
        if (form.getValues('phoneNumber') !== userPhoneFormatter()) {
          form.reset(formValues => ({ ...formValues, phoneNumber: userPhoneFormatter(), shouldDisplayPhoneError: true }))
        }
        // if rejected and the notification was change revert to the prev notification value and display a notification error
        else if (form.getValues().userTextNotificationsEnabled !== (userInfo as IUser)?.userTextNotificationsEnabled) {
          form.reset(formValues => ({ ...formValues, userTextNotificationsEnabled: (userInfo as IUser)?.userTextNotificationsEnabled, shouldDisplayNotificationError: true }))
        }
      }
    }
  });

  useApiData(updatePreferredName, {
    onFulfilled(data) {
      updateFormDataFromServer(data);
    },
    // If update profile rejected -> revert to the previous values and display an error
    onRejected(error) {
      if (isErrorCodeNotForbiddenOrUnauthorized(error.code)) {
        form.reset(formValues => ({ ...formValues, preferredName: (userInfo as IUser)?.preferredName || "", shouldDisplayNameError: true }))
      }
    }
  });

  const updateFormDataFromServer = (data: IUser) => {
    setUserinfo(data);

    form.reset(formData => ({
      ...formData,
      phoneNumber: userPhoneFormatter(data?.phoneNumber ?? ''),
      userTextNotificationsEnabled: !!data?.userTextNotificationsEnabled,
      preferredName: data?.preferredName ?? ''
    }));
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmitForm)}>
      <EditItemSection
        register={form.register(`preferredName`, { required: true, onBlur: onSubmitForm })}
        desktopLabel={t("settingsPersonalInfoNameLabelDesktop")}
        mobileLabel={t("settingsPersonalInfoNameLabelMobile")}
        inputValue={form.getValues().preferredName}
        isInputEditable={watchIsNameEditable}
        onFocusInput={() => onFocusInput('preferredName')}
        shouldDisplayError={watchShouldDisplayNameError}
        inputName="preferredName"
        editTestId="edit-name"
        inputType="text"
      />
      <EditItemSection
        register={form.register(`phoneNumber`, { required: true, minLength: userPhoneLength, maxLength: userPhoneLength, onBlur: onSubmitForm, onChange: handlePhoneNumberFormatChange })}
        desktopLabel={t("settingsPersonalInfoPhoneLabelDesktop")}
        mobileLabel={t("settingsPersonalInfoPhoneLabelMobile")}
        inputValue={form.getValues().phoneNumber}
        isInputEditable={watchIsPhoneEditable}
        onFocusInput={() => onFocusInput('phoneNumber')}
        shouldDisplayError={watchShouldDisplayPhoneError}
        inputName="phoneNumber"
        editTestId="edit-phone"
        inputClassName='phone'
        maxLengthInput={userPhoneLength}
        inputType="tel"
      />
      <section className='notification'>
        <div>
          <h3 className='title static-string mobile-label-text'>{t("settingsPersonalInfoNotificationTitleMobile")}</h3>
          <h3 className='title static-string desktop-label-text'>{t("settingsPersonalInfoNotificationTitleDesktop")}</h3>
          <p>{t("settingsPersonalInfoNotificationSubTitle")}</p>
        </div>
        <Switch
          onChange={(checked) => onChangeNotificationSwitch(checked)}
          checked={form.getValues().userTextNotificationsEnabled}
          data-testid="notification-switch"
        />
        {watchShouldDisplayNotificationError && <ErrorMessage />}
      </section>
    </form>
  )
}