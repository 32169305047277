import { useTranslation } from "react-i18next";
import { inviteFriendsLink, userInfoLocalStorageKey } from "../../../../../../app/constants";
import { useEffect, useRef, useState } from "react";
import { ApplicationInsightsApi } from "../../../../../../application-insights";
import { useLocalStorage } from "../../../../../utils/useLocalStorage";
import { IUser } from "../../../../../../app/auth/auth.interfaces";
import './CopyLink.scss';

export const CopyLink = () => {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);
  const [userInfo,] = useLocalStorage(userInfoLocalStorageKey, '');
  const copyTimerRef = useRef<NodeJS.Timeout | null>(null);
  const [shouldDisplayError, setShouldDisplayError] = useState(false);

  const clearTimer = () => {
    if (copyTimerRef.current) clearTimeout(copyTimerRef.current);
  }

  useEffect(() => {
    return clearTimer();
  }, [])

  const copyLinkToClipboard = async () => {
    // remove error if exist
    if(shouldDisplayError) setShouldDisplayError(false);
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(inviteFriendsLink((userInfo as IUser)?.id))
        .then(() => {
          // If successful, update the isCopied state value
          setIsCopied(true);
          clearTimer()
          copyTimerRef.current = setTimeout(() => {
            setIsCopied(false);
          }, 2000)
        })
        // The promise is rejected if the caller does not have permission to write to the clipboard.
        .catch((err) => {
          setShouldDisplayError(true);
          ApplicationInsightsApi.trackException(err);
        });
    } else setShouldDisplayError(true);
  }

  return (
    <div className='copy-link-wrapper'>
      <div className="copy-link-flex-container">
        <h3 className='static-string'>{t("settingsInviteFriendsTitle")}</h3>
        <div className="flex-desktop">
          <div className="copy-link-text text">{t("settingsCopyLinkParagraph")}</div>
          <div className="link-content">{inviteFriendsLink((userInfo as IUser)?.id)}</div>
        </div>
        <button className={`copy-button copy-button-desktop ${isCopied && 'copied'}`} onClick={copyLinkToClipboard} disabled={isCopied}>{isCopied ? t("settingsLinkCopiedButtonText") : t("settingsCopyLinkButtonText")}</button>
      </div>
      <div className="copy-button-container">
        <button className={`copy-button ${isCopied && 'copied'}`} onClick={copyLinkToClipboard} disabled={isCopied}>{isCopied ? t("settingsLinkCopiedButtonText") : t("settingsCopyLinkButtonText")}</button>
      </div>
      {shouldDisplayError && <small className="app-error error">{t("settingsPageCopyLinkError")}</small>}
    </div>
  )
}