import React, { FunctionComponent, useMemo, useRef, useState } from 'react';
import { EDayjsFormatter, getDateAndTimeWithoutYear } from '../../../../../../../shared/utils/dateFormat';
import dayjs from 'dayjs';
import calendarIcon from '../../../../../../../assets/images/calendar-due-date-neutral-3.svg';
import priorityStarOnIcon from '../../../../../../../assets/images/priority-star-on.svg';
import priorityStarOffIcon from '../../../../../../../assets/images/priority-star-off.svg';
import { useAppDispatch, useAppSelector } from '../../../../../../../app/store';
import { ETaskStatus, ITaskFields, IMessageDataTask, ITaskUpdateReqPayload } from '../../../../../resizable-container/stage-container/stage-tasks/stageTasks.interface';
import { useLocalStorage } from '../../../../../../../shared/utils/useLocalStorage';
import { chatSessionIdSessionStorageKey } from '../../../../../../../app/constants';
import { getTasksListReqAction, setSelectedTaskForEditing, setShouldOpenAddEditTaskFrom, updateTaskReqAction } from '../../../../../resizable-container/stage-container/stage-tasks/stageTasks.store';
import { ApplicationInsightsApi } from '../../../../../../../application-insights';
import { setQueueMessageData } from '../../../../../chat.store';
import { batch } from 'react-redux';
import './TasksListSingleItem.scss';
import { createAutomatedTaskEvent, isOverdueDate } from '../TasksList.utils';

interface ITasksListSingleItemProps {
  singleTask: IMessageDataTask;
  className?: string;
  shouldHideOnComplete?: boolean;
}

const TasksListSingleItem: FunctionComponent<ITasksListSingleItemProps> = ({ singleTask, className, shouldHideOnComplete }) => {
  const [sessionIdLocalStorage,] = useLocalStorage(chatSessionIdSessionStorageKey, '');
  const { sessionId } = useAppSelector(store => store.chatReducer);
  const dispatch = useAppDispatch();
  const completeTimerRef = useRef<NodeJS.Timeout | null>(null);
  const removeTasksTime = 2000;
  // to hide a complete task if in chat list
  const [isHidden, setIsHidden] = useState(false);
  const completeEffect = useMemo(() => new Audio('/sounds/max_done.mp3'), []);
  const [isPriority, setIsPriority] = useState(singleTask?.priority || false);

  const updateTasksList = () => {
    dispatch(getTasksListReqAction());
  }

  const handlePlayCompleteEffect = () => {
    try {
      completeEffect.play();
    } catch (error) {
      ApplicationInsightsApi.trackException(error)
    }
  }

  const handleCheckbox = (e: React.FormEvent<HTMLInputElement>) => {
    const status = (e.target as HTMLInputElement).checked ? ETaskStatus.DONE : ETaskStatus.NOT_STARTED
    if (status === ETaskStatus.DONE) handlePlayCompleteEffect();
    onUpdateTask({ status: status })
    if (completeTimerRef.current) clearTimeout(completeTimerRef.current);
    completeTimerRef.current = setTimeout(() => {
      updateTasksList();
      if ((e.target as HTMLInputElement).checked && shouldHideOnComplete) setIsHidden(!isHidden);
    }, removeTasksTime);
  }

  const onOpenEditTaskFormModal = () => {
    dispatch(setSelectedTaskForEditing(singleTask));
    dispatch(setShouldOpenAddEditTaskFrom(true));
  }

  const setOverdueTaskClassName = () => {
    if (singleTask?.dueDate && isOverdueDate(singleTask.dueDate)) return 'overdue';
    return '';
  }

  const onUpdateTask = (updatedField: ITaskFields, shouldSendMsgToTheBot:boolean = true): void => {
    const currentSessionId = sessionId?.data?.sessionId || sessionIdLocalStorage;

    const reqPayload: ITaskUpdateReqPayload = Object.assign({}, { sessionId: currentSessionId, id: singleTask.id }, updatedField);

    dispatch(updateTaskReqAction(reqPayload)).unwrap()
      .then(() => {
        batch(() => {
          const fieldsName = Object.keys(updatedField)[0] as keyof ITaskFields;
          if(shouldSendMsgToTheBot) dispatch(setQueueMessageData({ type: 'automatic', botRequestJson: JSON.stringify(createAutomatedTaskEvent(currentSessionId, fieldsName, updatedField[fieldsName], singleTask)) }));
          if (fieldsName !== 'status') updateTasksList();
        });
      })
      // revert in failed
      .catch(() => {
        if(Object.keys(updatedField)[0] === 'priority') setIsPriority(!updatedField[Object.keys(updatedField)[0] as keyof ITaskFields ]);
      })
  }

  return (
    <div className={`tasks-list-item ${className ? className : ''} ${isHidden ? 'd-none' : 'displayed'} ${setOverdueTaskClassName()}`} data-testid="tasks-list-item">
      <section className="task-item-left">
        <label className='task-complete-label'>
          <input className='complete-checkbox' type="checkbox" onInput={handleCheckbox} defaultChecked={singleTask?.status === ETaskStatus.DONE} />
        </label>
        <div className='open-edit-task-container' onClick={onOpenEditTaskFormModal}>
          <span className='task-name'>{singleTask.name}</span>
          {!!singleTask.dueDate &&
            <div className="task-item-due-date">
              <img src={calendarIcon} alt="calendar" />
              {dayjs(new Date(singleTask.dueDate)).format(`${EDayjsFormatter.PartOfMonthWord} ${EDayjsFormatter.DayOfMonthNumberWithoutZero}`).toUpperCase()}
            </div>
          }
        </div>
      </section>
      <section className='task-item-right'>
        {!!singleTask.workTime && <div className="task-item-work-time" onClick={onOpenEditTaskFormModal}>{getDateAndTimeWithoutYear(singleTask.workTime)}</div>}
        <button className='priority' onClick={() => {setIsPriority(!isPriority); onUpdateTask({ priority: !isPriority }, false)}}><img src={isPriority ? priorityStarOnIcon : priorityStarOffIcon} alt="star" /></button>
      </section>
    </div>
  )
}

export default TasksListSingleItem;
