import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../app/store';
import { setTasksFilter } from '../stageTasks.store';
import { ETasksGroupsType } from '../stageTasks.interface';
import arrowDownIcon from '../../../../../../assets/images/arrow-down.svg';
import { useTranslation } from 'react-i18next';
import './StageTasksDropdownFilters.scss';
import { getTasksGroupEmoji } from '../../../../chat/chat-conversation/message-data-card/tasks-list/TasksList.utils';

export const StageTasksDropdownFilters = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { tasksFilter } = useAppSelector(store => store.StageTasksReducer);
  const filterOptionsArr: ETasksGroupsType[] = [ETasksGroupsType.MY_TASKS, ETasksGroupsType.QUICK_WINS, ETasksGroupsType.UNSCHEDULED, ETasksGroupsType.OVERDUE, ETasksGroupsType.DONE];
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = () => {
      // if click at the document and the dropDown is open, close it
      if (dropdownRef.current && dropdownRef.current.clientHeight > 0) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const handleFilter = (filter: ETasksGroupsType) => {
    dispatch(setTasksFilter(filter));
  }

  return (
    <div className={`stage-tasks-dropdown-filter ${isOpen ? 'open' : ''}`}>
      <button type='button' className="dropdown-toggle" onClick={() => setIsOpen(true)}>{getTasksGroupEmoji(tasksFilter)}{tasksFilter}</button>
      <div className={`dropdown-menu`} ref={dropdownRef}>
        <div className='dropdown-view'>{t('stageTasksDropDownTitle')} <img src={arrowDownIcon} alt="arrow-down" /></div>
        {filterOptionsArr.map(filterItem => (
          <div key={filterItem} className={`dropdown-item ${filterItem===ETasksGroupsType.MY_TASKS && 'my-tasks'}`} onClick={() => { handleFilter(filterItem) }}>
            {getTasksGroupEmoji(filterItem)}{filterItem}
          </div>
        ))}
      </div>
    </div>
  )
}