import { createAction, createReducer } from '@reduxjs/toolkit';
import { i18LanguageCode } from '../i18/i18n.codes';

export interface SharedStoreState  {
    languageCode: i18LanguageCode;
    isComeFromRegistration: boolean;
}

const initialStateSharedStore: SharedStoreState = {
    languageCode: 'en',
    isComeFromRegistration: false,
};


export const setLanguageCode = createAction<i18LanguageCode>('sharedStore/setLanguage');

export const setIsComeFromRegistration = createAction<boolean>('sharedStore/setIsComeFromRegistration');

export const sharedStoreReducer = createReducer(initialStateSharedStore, (builder) => {
    builder.addCase(setLanguageCode, (state, action) => {
        state.languageCode = action.payload;
    });
    builder.addCase(setIsComeFromRegistration, (state, action) => {
        state.isComeFromRegistration = action.payload;
    });
});
