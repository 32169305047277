import { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../../app/store'
import { ETaskStatus, IAddEditTaskForm, ICreateTaskReqPayload, ITaskUpdateReqPayload, ITaskFields } from '../stageTasks.interface';
import { useTranslation } from 'react-i18next';
import closeModalDeskIcon from "../../../../../../assets/images/close-create-task-modal-desk.svg";
import closeModalMobileIcon from "../../../../../../assets/images/close-create-task-modal-mobile.svg";
import trashDeskIcon from "../../../../../../assets/images/add-edit-trash-desk.svg";
import trashMobileIcon from "../../../../../../assets/images/add-edit-trash-mobile.svg";
import backIcon from "../../../../../../assets/images/back.svg";
import { FormProvider, useForm } from 'react-hook-form';
import SchedulingSection from './scheduling-section/SchedulingSection';
import dueDateIcon from '../../../../../../assets/images/add-edit-task-due-date-icon.svg'
import durationIcon from '../../../../../../assets/images/add-edit-task-duration-icon.svg'
import workTimeIcon from '../../../../../../assets/images/add-edit-task-work-time-icon.svg'
import { getDateAndTimeWithoutYear } from '../../../../../../shared/utils/dateFormat';
import { createTaskReqAction, getTasksListReqAction, setSelectedTaskForEditing, setShouldOpenAddEditTaskFrom, updateTaskReqAction } from '../stageTasks.store';
import { ConfirmModal } from '../../../../../../shared/components/confirm-modal/ConfirmModal';
import { useLocalStorage } from '../../../../../../shared/utils/useLocalStorage';
import { chatSessionIdSessionStorageKey } from '../../../../../../app/constants';
import createAppOverlayPopover from '../../../../../../shared/components/app-overlay-popover/createAppOverlayPopover';
import { TaskEditOverlay } from '../task-edit-overlays/TaskEditOverlay';
import { setQueueMessageData } from '../../../../chat.store';
import { minutesToHoursAndMinutesStringRepresentation } from '../../../../../../shared/utils/utils';
import { batch } from 'react-redux';
import PriorityAndQuickWinsSection from './PriorityAndQuickWinsSection';
import { createAutomatedTaskEvent } from '../../../../chat/chat-conversation/message-data-card/tasks-list/TasksList.utils';
import TaskTags from './task-tags/TaskTags';
import TaskNotes from './task-notes/TaskNotes';
import TaskDescription from './task-description/TaskDescription';
import './CreateOrEditTask.scss';

const CreateOrEditTask = () => {
  const { selectedTaskForEditing } = useAppSelector(store => store.StageTasksReducer);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [shouldDisplayDeleteConfirmModal, setShouldDisplayDeleteConfirmModal] = useState(false);
  const [sessionIdLocalStorage,] = useLocalStorage(chatSessionIdSessionStorageKey, '');
  const { sessionId } = useAppSelector(store => store.chatReducer);
  const taskForm = useForm<IAddEditTaskForm>({
    defaultValues: {
      name: selectedTaskForEditing?.name || "",
      dueDate: selectedTaskForEditing?.dueDate ? new Date(selectedTaskForEditing.dueDate) : undefined,
      duration: selectedTaskForEditing?.duration ? selectedTaskForEditing.duration : undefined,
      workTime: selectedTaskForEditing?.workTime ? new Date(selectedTaskForEditing.workTime) : undefined,
      isDone: selectedTaskForEditing?.status === ETaskStatus.DONE,
      priority: selectedTaskForEditing?.priority || false,
      quickWin: selectedTaskForEditing?.quickWin || false,
      description: selectedTaskForEditing?.description,
      tags: selectedTaskForEditing?.tags ? [...selectedTaskForEditing.tags] : [],
      dueDateReminder: selectedTaskForEditing?.dueDateReminder,
      workTimeReminder: selectedTaskForEditing?.dueDateReminder,
      notes: selectedTaskForEditing?.notes || undefined,
    }
  });
  
  const dueDateWatch = taskForm.watch("dueDate");
  const durationWatch = taskForm.watch("duration");
  const workTimeWatch = taskForm.watch("workTime");
  const nameWatch = taskForm.watch("name");
  const priorityWatch = taskForm.watch("priority");
  const quickWinWatch = taskForm.watch("quickWin");
  const dueDateReminderWatch = taskForm.watch("dueDateReminder");
  const workTimeReminderWatch = taskForm.watch("workTimeReminder");

  const onClose = () => {
    batch(() => {
      dispatch(setSelectedTaskForEditing(null));
      dispatch(setShouldOpenAddEditTaskFrom(false));
    });
  };

  const onSubmitCreateNewTask = (formData: IAddEditTaskForm) => {
    // only in create
    if (!selectedTaskForEditing) {
      // call to create-task request
      const reqPayload: ICreateTaskReqPayload = {
        sessionId: sessionId?.data?.sessionId || sessionIdLocalStorage,
        name: formData.name,
        dueDate: formData.dueDate ? formData.dueDate.toISOString() : null,
        duration: formData.duration,
        workTime: formData.workTime ? formData.workTime.toISOString() : null,
        status: formData.isDone ? ETaskStatus.DONE : ETaskStatus.NOT_STARTED,
        reminderTime: null,
        priority: formData?.priority || false,
        quickWin: formData?.quickWin || false,
        description: formData.description || null,
        notes: formData.notes || null,
        tags: formData.tags || []
      }
      dispatch(createTaskReqAction(reqPayload)).then(() => {
        dispatch(getTasksListReqAction());
        onClose();
      });
    }
  };

  const onUpdateTask = (updatedField: ITaskFields, shouldSendMsgToTheBot: boolean = true): void => {
    if (!!selectedTaskForEditing) {
      const currentSessionId = sessionId?.data?.sessionId || sessionIdLocalStorage;

      const reqPayload: ITaskUpdateReqPayload = Object.assign({}, { sessionId: currentSessionId, id: selectedTaskForEditing.id }, updatedField);

      dispatch(updateTaskReqAction(reqPayload)).unwrap()
        .then((updatedTask) => {
          batch(() => {
            if (shouldSendMsgToTheBot) dispatch(setQueueMessageData({ type: 'automatic', botRequestJson: JSON.stringify(createAutomatedTaskEvent(currentSessionId, Object.keys(updatedField)[0], updatedField[Object.keys(updatedField)[0] as keyof ITaskFields], selectedTaskForEditing)) }));
            dispatch(getTasksListReqAction());
            if (updatedField?.status === ETaskStatus.DELETED) onClose();
            else dispatch(setSelectedTaskForEditing(updatedTask));
          });
        });
    }
  }

  const onUpdateTaskTextarea = (updatedField: ITaskFields, shouldSendMsgToTheBot: boolean = true) => {
    const fieldName = Object.keys(updatedField)[0] as keyof ITaskFields;
    if (!!updatedField[fieldName] && taskForm.getFieldState(fieldName as keyof IAddEditTaskForm).isDirty) {
      onUpdateTask(updatedField, shouldSendMsgToTheBot);
      // will reset isDirty back to false
      taskForm.resetField(fieldName as keyof IAddEditTaskForm, { defaultValue: updatedField[fieldName] });
    }
  }

  const onChangeDueDateAndWorkTime = (fieldName: 'dueDate' | 'workTime', date: Date, reminder?: number | null) => {
    onUpdateTask({ [fieldName]: date ? (date as Date).toISOString() : null });
    taskForm.setValue(fieldName, date || null);
    onUpdateReminderFields(fieldName, reminder);
  }

  const onUpdateReminderFields = (reminderType: "dueDate" | "workTime", reminder?: number | null) => {
    const reminderFieldName = reminderType === "dueDate" ? "dueDateReminder" : "workTimeReminder";
    onUpdateTask({ [reminderFieldName]: reminder || null }, false);
    taskForm.setValue(reminderFieldName, reminder || null);
  }

  return (
    <FormProvider {...taskForm}>
      <div className='add-edit-task-container'>
        <button type='button' className='close-modal' onClick={onClose}>
          {selectedTaskForEditing ?
            <img src={backIcon} alt="back" />
            :
            <>
              <img className='--desktop' src={closeModalDeskIcon} alt="close" />
              <img className='--mobile' src={closeModalMobileIcon} alt="close" />
            </>
          }
        </button>
        <PriorityAndQuickWinsSection
          onUpdateTask={onUpdateTask}
          priorityWatch={priorityWatch}
          quickWinWatch={quickWinWatch}
          className="--mobile" />
        <form className='add-edit-task-form' onSubmit={taskForm.handleSubmit(onSubmitCreateNewTask)}>
          <section className="details-section">
            <div className="name-controller">
              <label>
                <input
                  onChange={(e) => {
                    taskForm.setValue("isDone", e.target.checked);
                    onUpdateTask({ status: e.target.checked ? ETaskStatus.DONE : ETaskStatus.NOT_STARTED })
                  }}
                  className='complete-checkbox'
                  type="checkbox"
                  checked={taskForm.watch("isDone")} />
              </label>
              <input
                {...taskForm.register("name", { required: true, onBlur: () => onUpdateTaskTextarea({ name: nameWatch }) })}
                className='name-text'
                type="text"
                placeholder={t("addEditTaskNamePlaceholderText")}
                defaultValue={taskForm.getValues("name")}
                autoComplete="off" />
              {taskForm.formState.errors.name && <small className='task-name-error'>{t("createTaskNameFieldError")}</small>}
              <PriorityAndQuickWinsSection
                onUpdateTask={onUpdateTask}
                priorityWatch={priorityWatch}
                quickWinWatch={quickWinWatch}
                className="--desktop" />
            </div>
            <TaskDescription onUpdateTaskTextarea={onUpdateTaskTextarea} />
            <TaskTags onUpdateTask={onUpdateTask} />
            <div className="scheduling-container">
              <h3 className='create-or-edit-sub-title'>{t("addEditTaskSchedulingTitle")}</h3>
              <SchedulingSection
                title={t("addEditTaskDueDateTitle")}
                formValue={dueDateWatch ? getDateAndTimeWithoutYear(dueDateWatch) : ''}
                placeholder={t("addEditTaskDueDateText")} icon={dueDateIcon}
                reminderType={"dueDate"}
                reminderValue={dueDateReminderWatch}
                onUpdateReminderFields={onUpdateReminderFields}
                onClick={() => createAppOverlayPopover(<TaskEditOverlay
                  overlayType='dueDate'
                  date={taskForm.getValues('dueDate')}
                  reminder={dueDateReminderWatch}
                  onChange={(date, reminder) => onChangeDueDateAndWorkTime("dueDate", date as Date, reminder)} />)} />
              <SchedulingSection
                title={t("addEditTaskDurationTitle")}
                formValue={durationWatch ? minutesToHoursAndMinutesStringRepresentation(Number(durationWatch / 60)) : ''}
                placeholder={t("addEditTaskDurationText")}
                icon={durationIcon}
                onClick={() => createAppOverlayPopover(<TaskEditOverlay
                  overlayType='duration'
                  formValueSetter={taskForm.setValue}
                  formValueName='duration'
                  duration={taskForm.getValues('duration')}
                  onChange={(duration) => onUpdateTask({ duration: duration ? duration as number : null })} />)} />
              <SchedulingSection
                title={t("addEditTaskWorkDateTimeTitle")}
                formValue={workTimeWatch ? getDateAndTimeWithoutYear(workTimeWatch) : ''}
                placeholder={t("addEditTaskWorkDateTimeText")}
                icon={workTimeIcon}
                reminderType={"workTime"}
                reminderValue={workTimeReminderWatch}
                onUpdateReminderFields={onUpdateReminderFields}
                onClick={() => createAppOverlayPopover(<TaskEditOverlay
                  overlayType='workTime'
                  date={taskForm.getValues('workTime')}
                  reminder={workTimeReminderWatch}
                  onChange={(date, reminder) => onChangeDueDateAndWorkTime("workTime", date as Date, reminder)} />)} />
            </div>
            <TaskNotes onUpdateTaskTextarea={onUpdateTaskTextarea} />
          </section>
          <section className="action-btn-section">
            {selectedTaskForEditing ?
              <button
                type='button'
                className='delete-task'
                onClick={() => setShouldDisplayDeleteConfirmModal(true)}
              >
                <img className='trash-desktop --desktop' src={trashDeskIcon} alt="trash" />
                <img className='trash-mobile --mobile' src={trashMobileIcon} alt="trash" />
              </button>
              :
              <button type='submit'>{t("addNewTaskSubmitButtonText")}</button>
            }
          </section>
        </form>
        {shouldDisplayDeleteConfirmModal &&
          <ConfirmModal
            title={t("deleteTaskConfirmModalTitle")}
            informationText={t("deleteTaskConfirmModalSubTitle")}
            confirmBtnText={t("settingsResetProfileConfirmModalConfirmButtonText") + "."}
            onConfirm={() => onUpdateTask({ status: ETaskStatus.DELETED }, false)}
            onCancel={() => setShouldDisplayDeleteConfirmModal(false)}
          />}
      </div>
    </FormProvider>
  )
}

export default CreateOrEditTask;
