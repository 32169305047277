import { Outlet } from 'react-router-dom';
import { setAppInsightsUserContext } from '../../application-insights';
import { InitApp } from './InitApp';

export const Main = () => {
    setAppInsightsUserContext();

    return (
        <div className="app-main">
            <InitApp>
                <Outlet />
            </InitApp>
        </div>
    );
};
