export interface ITaskFields {
  reminderTime?: string | null;
  dueDate?: string | null | Date;
  workTime?: string | null | Date;
  duration?: number | undefined | null; // seconds
  priority?: boolean;
  quickWin?: boolean;
  description?: string | null;
  notes?: string | null;
  name?: string;
  status?: ETaskStatus;
  tags?: ITaskTag[];
  dueDateReminder?: number | null;
  workTimeReminder?: number | null;
}
export interface IMessageDataTask extends ITaskFields {
  id: string;
  name: string;
  creationTime: string;
  status: ETaskStatus;
  completionTime?: string;
}

export interface IStageTasksListResponse {
  tasks: IMessageDataTask[];
  timestampForDelta: number;
}

export enum ETasksGroupsType {
  OVERDUE = "Late",
  SCHEDULED = "Scheduled",
  UNSCHEDULED = "Unscheduled",
  DONE = "Done",
  MY_TASKS = "My Tasks",
  QUICK_WINS = "Quick Wins",
  PRIORITY = "Priority",
}

export enum ETaskStatus {
  NOT_STARTED = "NotStarted",
  DONE = 'Done',
  DELETED = 'Deleted'
}

export interface ITaskUpdateReqPayload extends ITaskFields {
  sessionId: string;
  id: string;
}

export interface ICreateTaskReqPayload extends ITaskFields {
  sessionId: string;
  name: string;
  status: ETaskStatus;
}

export interface IAddEditTaskForm extends ITaskFields {
  name: string,
  isDone: boolean,
  workTime: Date | undefined,
  dueDate: Date | undefined,
  priority: boolean;
  quickWin: boolean;
}

export interface ITaskTag {
  name?: string | null, 
  color?: string | null
}