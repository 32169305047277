import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import welcomeScreen3Image from '../../../../assets/images/welcome-screen-3-image.png';

export const CarouselScreen3: FunctionComponent = () => {
  const { t } = useTranslation();
  
  return (
    <div className="carousel-element welcome-screen-3" data-testid="carousel-element">
      <h2 className='title'>{t('welcomeScreen3Title')}</h2>
      <div className="image-container"><img src={welcomeScreen3Image} alt={t("welcomeScreen3ImageAltText3")}/></div>
      <p className='extension-text'>{t("welcomeScreen3Paragraph1")}</p>
      <p className="extension-text section-2">{t("welcomeScreen3Paragraph2")}</p>
    </div>
  )
}