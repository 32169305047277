import './App.scss';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Suspense, useEffect, useState } from 'react';
import { I18Provider } from './shared/i18/i18nProvider';
import { useAppSelector } from './app/store';
import { ConfigProvider } from 'antd';
import i18n from './shared/i18/i18n';
import { defaultAntdLocale, locales } from './shared/i18/antd_locales';
import { Page404 } from './shared/components/page404/Page404';
import { ChatWrapper } from './features/chat-wrapper/ChatWrapper';
import { APP_ROUTING_PATHS } from './app/constants';
import { Login } from './app/auth/login/Login';
import { AuthLayout } from './app/auth/auth-layout/AuthLayout';
import { Auth } from './app/auth/Auth';
import { PrivateRoute } from './app/guards/PrivateRoute';
import { Main } from './app/main/Main';
import { CheckLogin } from './app/auth/check-login/CheckLogin';
import { Register } from './app/auth/register/Register';
import { ProtectedRegisterRoute } from './app/guards/ProtectedRegisterRoute';
import { AddUserPhoneRegistrationForm } from './app/auth/register/add-user-phone-form/AddUserPhoneRegistrationForm';
import AppEventListenerHandler from './shared/components/appEventListenerHandler/AppEventListenerHandler';
import { StayLoggedIn } from './app/auth/stay-logged-in/StayLoggedIn';
import { ProtectedStayLoggedInRoute } from './app/guards/ProtectedStayLoggedInRoute';
import { ProtectedLoginRoute } from './app/guards/ProtectedLoginRoute';
import { Redirect } from './app/guards/Redirect';
import { TabSwitchingEvenHandler } from './shared/components/tabSwitchingEvenHandler/TabSwitchingEvenHandler';
import AppModals from './shared/components/app-modals/AppModals';

function App() {
    const { languageCode } = useAppSelector((store) => store.sharedStoreReducer);
    const [direction, setDirection] = useState<'ltr' | 'rtl' | null>(null);

    useEffect(() => {
        setDirection(i18n.dir(languageCode));
    }, [languageCode]);

    return (
        <div className="App">
            <Suspense fallback="loading">
                <I18Provider>
                    <AppEventListenerHandler />
                    <TabSwitchingEvenHandler />
                    {direction && (
                        <ConfigProvider direction={direction} locale={locales[languageCode] || defaultAntdLocale}>
                            <Auth>
                                <Routes>
                                    <Route path={APP_ROUTING_PATHS.AUTHLAYOUT} element={<AuthLayout />}>
                                        <Route path={APP_ROUTING_PATHS.LOGIN} element={<ProtectedLoginRoute><Login /></ProtectedLoginRoute>} />
                                        <Route path={APP_ROUTING_PATHS.SSO} element={<CheckLogin />} />
                                        <Route path={APP_ROUTING_PATHS.STAY_LOGGED_IN} element={<ProtectedStayLoggedInRoute><StayLoggedIn /></ProtectedStayLoggedInRoute>} />
                                        <Route path={APP_ROUTING_PATHS.REGISTER} element={<ProtectedRegisterRoute><Register /></ProtectedRegisterRoute>} />
                                        <Route path={APP_ROUTING_PATHS.ADD_PHONE_REGISTER} element={<ProtectedRegisterRoute><AddUserPhoneRegistrationForm /></ProtectedRegisterRoute>} />
                                        <Route path={APP_ROUTING_PATHS.AUTHLAYOUT} element={<Navigate to={APP_ROUTING_PATHS.LOGIN} replace />} />
                                    </Route>
                                    <Route path={APP_ROUTING_PATHS.HOME} element={<PrivateRoute><><AppModals /><Main /></></PrivateRoute>}>
                                        <Route path={APP_ROUTING_PATHS.CHAT} element={<ChatWrapper />} />
                                    </Route>
                                    <Route path={APP_ROUTING_PATHS.REDIRECT} element={<Redirect />} />
                                    <Route path={APP_ROUTING_PATHS.PAGE_404} element={<Page404 />} />
                                    <Route path="*" element={<Navigate to={APP_ROUTING_PATHS.PAGE_404} replace />} />
                                    <Route path="/" element={<Navigate to={APP_ROUTING_PATHS.REDIRECT} replace />} />
                                </Routes>
                            </Auth>
                        </ConfigProvider>
                    )}
                </I18Provider>
            </Suspense>
            <div id='app-overlay-popover-container' />
        </div>
    );
}

export default App;
