import { Navigate } from "react-router-dom";
import { useLocalStorage } from "../../shared/utils/useLocalStorage";
import { APP_ROUTING_PATHS, shouldStayLoggedInLocalStorageKey, tokenLocalStorageKey, userInfoLocalStorageKey } from "../constants";
import { useAppSelector } from "../store";
import { keysExistInSessionOrLocalStorage } from "../../shared/utils/keysExistInSessionOrLocalStorage";

export const Redirect = () => {
  const [userInfo,] = useLocalStorage(userInfoLocalStorageKey, '');
  const { loginRes, registrationRes, updatePhoneNumberRes } = useAppSelector(store => store.authReducer);
  const { userAppData } = useAppSelector(store => store.userReducer);
  const [tokenLocalStorage,] = useLocalStorage(tokenLocalStorageKey, '');

  const redirect = ():JSX.Element => {
    const user = updatePhoneNumberRes.data || registrationRes.data || userAppData.data?.user || userInfo;
    const token = loginRes.data?.token || tokenLocalStorage;
    const isStayLoggedInExist = keysExistInSessionOrLocalStorage([shouldStayLoggedInLocalStorageKey]);

      switch (true) {
        case !token:
          return <Navigate to={APP_ROUTING_PATHS.LOGIN} />
        case !isStayLoggedInExist:
          return <Navigate to={APP_ROUTING_PATHS.STAY_LOGGED_IN} />;
        case !user:
          return <Navigate to={APP_ROUTING_PATHS.REGISTER} />;
        case user && !user?.phoneNumber:
          return <Navigate to={APP_ROUTING_PATHS.ADD_PHONE_REGISTER} />;
        case user && !!user?.phoneNumber:
          return <Navigate to={`${APP_ROUTING_PATHS.HOME}/${APP_ROUTING_PATHS.CHAT}`} />;
        default:
          return <Navigate to={APP_ROUTING_PATHS.PAGE_404} />
      }
  }

  return redirect();
}