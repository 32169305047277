export const tokenLocalStorageKey = "maximalLearningToken"
export const refreshTokenLocalStorageKey = "maximalLearningRefreshToken"
export const userInfoLocalStorageKey = "maximalLearningUserInfo"
export const shouldStayLoggedInLocalStorageKey = "maximalLearningShouldStayLoggedIn"
export const loginTypeStorageKey = "maximalLearningLoginType"
export const selectedOptionPerMsgIdKey = "selectedOptionPerMsgId";
export const chatSessionIdSessionStorageKey = "sessionId";
export const redirectUrlStorageKey = "redirectUrl";
export const sessionIdRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
export const maximalLearningDomain = "https://www.maximallearning.com";
export const privacyPolicyLink = `${maximalLearningDomain}/privacy`;
export const termsOfServiceLink = `${maximalLearningDomain}/tos`;
export const contactUsLink = `${maximalLearningDomain}/contact`;
export const discordLink = `https://discord.com/invite/kS4Q9Wq393`;
export const messageTestId = 'message';
export const dislikeFeedbackParam = 'dislikeFeedback';
export const settingsMenuParam = 'settingsMenu';
export const chatHistoryParam = 'chatHistory';
export const tasksListParam = 'tasksList';
export const userPhoneLength = 12;
export const numberOfMessagesToFetch = 100;
export const inviteFriendsLink = (userId: string) => "https://maximallearning.typeform.com/studentconsent?utm_content=inapp&utm_source="+userId;
export const messagesCounterStorageKey = "messagesCounter";
export const messagesAmountToDisplayFeedbackModal = 10;
export const stageParam = 'stageContent';
export const colorPalette = ["transparent", "#F9BB42", "#FF8A48", "#F1543F", "#E64AD6", "#B347F5", "#7748FF", "#2F43F2", "#3FB0E1", "#19C695", "#8FB300"];

export const APP_ROUTING_PATHS = {
    PAGE_404: 'page-404',
    HOME_PAGE: 'home-page',
    HOME: '/app',
    AUTHLAYOUT: '/auth',
    LOGIN: '/auth/login',
    SSO: '/auth/sso',
    STAY_LOGGED_IN: '/auth/stay-logged-in',
    REGISTER: '/auth/register',
    ADD_PHONE_REGISTER: '/auth/add-phone',
    CHAT: 'chat',
    REDIRECT: '/redirect',
};

export const API_ROUTES = {
    CHAT: {
        CREATE_OR_CONTINUE_SESSION: '/CreateOrContinueSession',
        HUMAN_STUDENT_TURN: '/HumanStudentTurn',
        FEEDBACK: '/MessageFeedback ',
        HISTORY: '/History',
        SESSION_SUMMARIES: '/SessionSummaries',
        DOWNLOAD_SESSION: '/DownloadSessionAsDocx',
    },
    AUTH: {
        REFRESHTOKEN: '/auth/refresh-token/',
        LOGIN: 'auth/login/',
        CALENDAR_LOGIN: 'auth/login/google-calendar',
        REGISTER: 'auth/register',
        REDIRECT: 'auth/redirect',
    },
    SETTINGS: {
        USER_PROFILE: '/userProfile',
        RESET_USER_PROFILE: '/reset',
        USER_FEEDBACK: '/userFeedback',
        PREFERRED_NAME: '/preferredName',
        REMOVE_CALENDAR_SYNC: '/removeCalendarSync',
    },
    USER: {
        UPDATE_USER_INFO: 'user/update/info',
        USER_APP_DATA: '/userAppData'
    },
    SPEECH: {
        GET_SPEECH_SERVICE_TOKEN: 'Speech/GenerateToken'
    },
    TASKS: {
        TASKS_LIST: '/tasks/list',
        UPDATE_TASK: '/tasks/update',
        CREATE_TASK: '/tasks/create',
    }
};


export const automaticBotNotificationEvents = {
    UX_SET_TASK_FIELD: '[UX_SET_TASK_FIELD]',
}