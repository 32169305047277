import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { WelcomeCarousel } from './welcome-carousel/WelcomeCarousel';
import { useAppDispatch, useAppSelector } from '../../app/store';
import { createOrContinueSession } from './chat.store';
import { ResizableContainer } from './resizable-container/ResizableContainer';
import './ChatWrapper.scss';

export const ChatWrapper: FunctionComponent = () => {
  const { isComeFromRegistration } = useAppSelector(store => store.sharedStoreReducer);
  // this carousel open only after registration
  const [isWelcomeCarouselOpen, setIsWelcomeCarouselOpen] = useState(isComeFromRegistration);
  const componentShouldLoad = useRef(true);
  const dispatch = useAppDispatch();

  const onStartSession = useCallback(() => {
    // call createOrContinueSession Api
    dispatch(createOrContinueSession());
  }, [dispatch])

  useEffect(() => {
    if (componentShouldLoad.current) {
      componentShouldLoad.current = false;
      onStartSession();
    }
  }, [onStartSession]);

  return (
    <div className="chat-wrapper">
      {!isWelcomeCarouselOpen && <ResizableContainer />}
      {/* This carousel will be displayed only after registration. */}
      <WelcomeCarousel isWelcomeCarouselOpen={isWelcomeCarouselOpen} setIsWelcomeCarouselOpen={setIsWelcomeCarouselOpen} />
    </div>
  )
}