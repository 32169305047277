import { FunctionComponent, useState } from 'react';
import { API_ROUTES, APP_ROUTING_PATHS } from '../../constants';
import { ELoginType } from '../auth.interfaces';
import { useTranslation } from 'react-i18next';
import AppleLogo from '../../../assets/images/Apple-logo-white.png';
import GoogleLogo from '../../../assets/images/Google-logo.png';
import DiscordLogo from '../../../assets/images/Discord-logo.png';
import { useAppSelector } from '../../store';
import { EAPIStatus } from '../../../shared/api/models';
import { LottieAppLoader } from '../../../shared/components/lottie-loader/LottieLoader';
import './Login.scss';

export const baseUrl = window.location.origin;
export const Login: FunctionComponent = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { loginRes } = useAppSelector(store => store.authReducer)

  const authRedirect = (provider: ELoginType) => {
    setIsLoading(true);
    window.location.href = `${process.env.REACT_APP_BASE_URL_CSHARP}/${API_ROUTES.AUTH.REDIRECT}/${provider}?redirectUri=${baseUrl}${APP_ROUTING_PATHS.SSO}`;
  }

  const shouldDisplayError = (): boolean => {
    return (
      // if there is an error from the server(status rejected) or token/refreshToken are not exist in the response(status fulfilled) -> display an error.
      loginRes.status === EAPIStatus.REJECTED || 
      (loginRes.status === EAPIStatus.FULFILLED && (!!!loginRes.data?.refreshToken || !!!loginRes.data?.token))
    );
  }

  return (
    <div className="login-container">
      {isLoading ?
        <LottieAppLoader testId='lottie-loader' />
        :
        <div className='flex-column'>
          <div className='login-title-container'>
            <div className='max-img'></div>
            <h1 className='login-title'>{t("wholeNewWayToLearn")} <br /> {t("signUpToSeeTheFuture")}</h1>
          </div>
          <div className="login-options">
            <button className='apple-login' disabled={isLoading} onClick={() => authRedirect(ELoginType.APPLE)} data-testid="apple-login"><img className='sign-up-apple' src={AppleLogo} alt={t("loginAppleLogoImageAltText")} />{t("continueWithApple")}</button>
            <button className='google-login' disabled={isLoading} onClick={() => authRedirect(ELoginType.GOOGLE)} data-testid={`google-login`}><img src={GoogleLogo} alt={t("loginGoogleLogoImageAltText")} />{t("continueWithGoogle")}</button>
            <button className='discord-login' disabled={isLoading} onClick={() => authRedirect(ELoginType.DISCORD)} data-testid={`discord-login`}><img src={DiscordLogo} alt={t("loginDiscordLogoImageAltText")} />{t("continueWithDiscord")}</button>
          </div>
          {shouldDisplayError() && <small className='app-error login-error'>{t("somethingWentWrongTryAgainError")}</small>}
        </div>
      }
    </div>
  )
}
