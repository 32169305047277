import { useState, useEffect } from 'react';
import { DayPicker } from 'react-day-picker';
import './AppSingleCalendar.scss';
import { ITaskEditorOverlayType } from '../../../features/chat-wrapper/resizable-container/stage-container/stage-tasks/task-edit-overlays/TaskEditOverlay';

// TODO: add support for multiple dates selection, date ranges
// TODO: add support for passing and setting react hook form control
// TODO: add support for custom styling

export interface IReactDayPickerCalendarProps {
    onSelect: (date: Date | undefined) => void;
    selectedDate?: Date | undefined;
    className?: string;
    overlayType: ITaskEditorOverlayType
}


export const AppSingleCalendar = ({ onSelect, selectedDate, className, overlayType }: IReactDayPickerCalendarProps) => {
    const [selected, setSelected] = useState<Date | undefined>(selectedDate);
    const [selectedMonth, setSelectedMonth] = useState<Date | undefined>(selectedDate);

    const onSelected = (date: Date | undefined) => {
        if (date) overlayType === 'workTime' ? date.setHours(17, 0) : date.setHours(23, 59);
        setSelected(date);
        onSelect(date);
    }

    useEffect(() => {
        if (!selectedDate) {
            setSelectedMonth(undefined);
            setSelected(undefined);
        } else {
            setSelected(selectedDate);
            setSelectedMonth(selectedDate);
        }
    }, [selectedDate]);

    return (
        <DayPicker
            className={className ? className : ''}
            onMonthChange={e => setSelectedMonth(e)}
            required mode='single'
            captionLayout="dropdown-buttons"
            month={selectedMonth || undefined}
            fromYear={new Date().getFullYear()}
            toYear={new Date().getFullYear() + 5}
            onSelect={onSelected}
            selected={selected}
            showOutsideDays fixedWeeks />
    )
}