import { ContentFrameWrapper } from '../../../../shared/components/content-frame-wrapper/ContentFrameWrapper'
import { EAppStageContentType } from './stageContainer.interfaces';
import { useSearchParams } from 'react-router-dom';
import { stageParam } from '../../../../app/constants';
import { StageTasks } from './stage-tasks/StageTasks';
import { StagePlanner } from './stage-planner/StagePlanner';

export const StageContainer = () => {
  const [searchParams,] = useSearchParams();

  const getStageContent = () => {
    switch (searchParams.get(stageParam)) {
      case EAppStageContentType.TASKS:
        return <StageTasks />;
      case EAppStageContentType.PLANNER:
        return <StagePlanner />;
      default:
        return <></>;
    }
  }

  return (
    <div className='stage-container'>
      <ContentFrameWrapper>
        {getStageContent()}
      </ContentFrameWrapper>
    </div>
  )
}

