import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from '../../../../utils/useLocalStorage';
import { IUser } from '../../../../../app/auth/auth.interfaces';
import { userInfoLocalStorageKey } from '../../../../../app/constants';
import { handleLogOut } from '../../../../utils/logOut';
import { Avatar } from '../../../avatar/Avatar';
import { getInitials } from '../../../../utils/getInitials';
import './TopSectionTitle.scss';

export const TopSectionTitle = () => {
  const { t } = useTranslation();
  const [userInfo,] = useLocalStorage(userInfoLocalStorageKey, '');

  return (
    <section className="settings-menu-top-section">
      <Avatar
        className='settings-avatar'
        defaultAvatarClassName='default-settings-avatar'
        size={100}
        avatarImg={userInfo?.profileImage}
        defaultContent={getInitials((userInfo as IUser)?.name || (userInfo as IUser)?.email)}
      />
      <div className="title">
        <div className="name-email">
          <h1 className='user-detail' data-testid="settings-menu-title">{(userInfo as IUser)?.name ?? ''}</h1>
          <div className='email'>{(userInfo as IUser)?.email ?? ''}</div>
        </div>
        <button type='button' onClick={handleLogOut} className='sign-out' data-testid='sign-out-btn'>{t("signOut")}</button>
      </div>
    </section>
  )
}