import Lottie from "lottie-react"
import pageLoaderAnimation from '../../animation-json/PageLoaderAnimation.json';
import { FunctionComponent } from "react";

interface ILottieAppLoaderProps {
  testId: string;
  className?: string;
}

export const LottieAppLoader: FunctionComponent<ILottieAppLoaderProps> = ({ testId, className = 'page-loader-animation' }) => {
  return (
    <Lottie
      animationData={pageLoaderAnimation}
      loop={true}
      className={className}
      data-testid={testId}
    />
  )
}