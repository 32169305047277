import { Dispatch, FunctionComponent, SetStateAction, useEffect, useRef, useState } from 'react';
import { ContentFrameWrapper } from '../../../shared/components/content-frame-wrapper/ContentFrameWrapper';
import { Carousel } from 'antd';
import { CarouselRef } from 'antd/es/carousel';
import arrowRight from '../../../assets/images/next-icon.svg';
import { CarouselScreen1 } from './carousel-screen-1/CarouselScreen1';
import { CarouselScreen2 } from './carousel-screen-2/CarouselScreen2';
import { CarouselScreen3 } from './carousel-screen-3/CarouselScreen3';
import { CarouselScreen4 } from './carousel-screen-4/CarouselScreen4';
import { useTranslation } from 'react-i18next';
import './WelcomeCarousel.scss';

interface IWelcomeCarouselProps {
  isWelcomeCarouselOpen: boolean;
  setIsWelcomeCarouselOpen: Dispatch<SetStateAction<boolean>>;
  isTheLastElementDisplayOnTheScreenInitialValue?: boolean
}

// this component display only after registration
export const WelcomeCarousel: FunctionComponent<IWelcomeCarouselProps> = ({ isWelcomeCarouselOpen, setIsWelcomeCarouselOpen, isTheLastElementDisplayOnTheScreenInitialValue = false }) => {
  const carouselRef = useRef<CarouselRef | null>();
  const carouselLength = 4;
  const [isTheLastElementDisplayOnTheScreen, setIsTheLastElementDisplayOnTheScreen] = useState(isTheLastElementDisplayOnTheScreenInitialValue);
  const [backgroundColor, setBackgroundColor] = useState<'toFirstBg' | 'toSecondBg'>('toFirstBg');
  // for removing the component from the dom after the fadeOut animation-that happened when the isWelcomeCarouselOpen set to false
  const [shouldShowComponent, setShouldShowComponent] = useState(isWelcomeCarouselOpen);
  const activeElementIndexRef = useRef<number>(0);
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      // clear carouselRef in destroy
      carouselRef.current = null;
    }
  }, [])

  const backgroundMapping = {
    toFirstBg: 'toSecondBg',
    toSecondBg: 'toFirstBg',
  }

  const onBeforeChange = (_: number, nextSlide: number) => {
    if (nextSlide === carouselLength - 1) setIsTheLastElementDisplayOnTheScreen(true);
    else if (nextSlide !== carouselLength - 1 && isTheLastElementDisplayOnTheScreen) setIsTheLastElementDisplayOnTheScreen(false);
    if (activeElementIndexRef.current !== nextSlide) {
      setBackgroundColor(backgroundMapping[backgroundColor] as 'toFirstBg' | 'toSecondBg');
      activeElementIndexRef.current = nextSlide;
    }
  };

  const onClickNext = () => {
    carouselRef.current?.next();
  }

  return (
    <>
      {shouldShowComponent &&
        <div
          className={`welcome-carousel-container ${backgroundColor} ${!isWelcomeCarouselOpen ? 'fadeOut' : ''}`}
          data-testid='welcome-carousel'
          onAnimationEnd={() => {
            setShouldShowComponent(false);
          }}
        >
          <ContentFrameWrapper>
            {!isTheLastElementDisplayOnTheScreen && <button tabIndex={0} className='next' onClick={onClickNext} data-testid="carousel-next-button"><img src={arrowRight} alt={t("welcomeScreenCarouselNextScreenAltText")} /></button>}
            <Carousel
              className='welcome-carousel'
              beforeChange={onBeforeChange}
              infinite={false}
              ref={(carousel) => (carouselRef.current = carousel)}
              aria-label={t('welcomeCarouselLabelText')}
            >
              <CarouselScreen1 />
              <CarouselScreen2 />
              <CarouselScreen3 />
              <CarouselScreen4 setIsWelcomeCarouselOpen={setIsWelcomeCarouselOpen} isTheLastElementDisplayOnTheScreen={isTheLastElementDisplayOnTheScreen} />
            </Carousel>
          </ContentFrameWrapper>
        </div>
      }
    </>
  )
}