let scrollTimer: NodeJS.Timeout | null = null;

export const createCssVhVariableByViewportHeight = () => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const height = window.visualViewport ? window.visualViewport.height : window.innerHeight;
  const vh = height * 0.01;
  // Then we set the value in the --availableViewportHeight custom property to the root of the document
  document.documentElement.style.setProperty('--availableViewportHeight', `${vh}px`);
  // when keyboard open scroll to the last message
  if (scrollTimer) clearTimeout(scrollTimer);
  scrollTimer = setTimeout(() => {
    const chatEl = document.querySelector('.chat-container .conversation');
    chatEl?.scrollTo(0, chatEl?.scrollHeight);
  }, 150)
}