import React from 'react';
import { useApiData } from '../../shared/hooks/useApiData';
import { useAppDispatch, useAppSelector } from '../store';
import { ILoginResponse } from './auth.interfaces';
import { useLocalStorage } from '../../shared/utils/useLocalStorage';
import { APP_ROUTING_PATHS, refreshTokenLocalStorageKey, tokenLocalStorageKey, userInfoLocalStorageKey } from '../constants';
import { useNavigate } from 'react-router-dom'
import { handleLogOut } from '../../shared/utils/logOut';
import { setAppInsightsUserContext } from '../../application-insights';
import { getUserAppData } from '../useAppData/user.store';
import { setAppModalContentType } from '../../shared/components/app-modals/appModals.store';
import { EAppModalContentType } from '../../shared/components/app-modals/appModals.interfaces';

export const Auth: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { loginRes, registrationRes } = useAppSelector(store => store.authReducer);
  const { resetUserProfile } = useAppSelector(store => store.settingsMenuReducer);
  const [, setAccessToken] = useLocalStorage(tokenLocalStorageKey, '');
  const [, setRefreshToken] = useLocalStorage(refreshTokenLocalStorageKey, '');
  const [, setUserInfo] = useLocalStorage(userInfoLocalStorageKey, '');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useApiData(loginRes, {
    onFulfilled(loginData) {
      if (loginData.token && loginData.refreshToken) {
        const { token, refreshToken } = loginData as ILoginResponse;
        setAccessToken(token);
        setRefreshToken(refreshToken);
        dispatch(getUserAppData()).unwrap()
        .then(data => {
          const { user } = data;
          if (!!user) {
            setUserInfo(user);
            setAppInsightsUserContext() ;
          }
          if (!!data.releaseNotes) dispatch(setAppModalContentType(EAppModalContentType.RELEASE_NOTES));
          navigate(APP_ROUTING_PATHS.REDIRECT);
        })
        .catch(() => {
          navigate(`${APP_ROUTING_PATHS.REDIRECT}`);
        })
      }
    },
    onRejected() {
      // if there is error message from the server navigate the user to redirect page there the user go back to the login
      navigate(`${APP_ROUTING_PATHS.REDIRECT}`);
    }
  });

  useApiData(registrationRes, {
    onFulfilled(registrationData) {
      setUserInfo(registrationData);
      setAppInsightsUserContext() ;
      if (registrationData.id) {
        navigate(APP_ROUTING_PATHS.REDIRECT);
      }
    } // on reject, the error message will display to the user on the registration screen
  });

  useApiData(resetUserProfile,{
    onFulfilled() {
      handleLogOut();
    },
  });

  return <>{children}</>;
};