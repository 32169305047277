import React from 'react'
import chatMaxIcon from '../../../../assets/images/navbar-chat-max.svg';
import chatMaxActiveIcon from '../../../../assets/images/navbar-chat-max-active.svg';
import tasksIcon from '../../../../assets/images/navbar-tasks.svg';
import tasksActiveIcon from '../../../../assets/images/navbar-tasks-active.svg';
import plannerIcon from '../../../../assets/images/navbar-planner.svg';
import plannerActiveIcon from '../../../../assets/images/navbar-planner-active.svg';
import { EAppStageContentType } from '../stage-container/stageContainer.interfaces';
import { NavButton } from './NavButton';
import './Navbar.scss';
import { useSearchParams } from 'react-router-dom';
import { Avatar } from '../../../../shared/components/avatar/Avatar';
import { settingsMenuParam, userInfoLocalStorageKey } from '../../../../app/constants';
import { IUser } from '../../../../app/auth/auth.interfaces';
import { getInitials } from '../../../../shared/utils/getInitials';
import { useLocalStorage } from '../../../../shared/utils/useLocalStorage';

export const Navbar = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [userInfo,] = useLocalStorage(userInfoLocalStorageKey, '');

  const onOpenSettingsMenu = () => {
    if (!searchParams.get(settingsMenuParam)) {
      setSearchParams(prev => {
        prev.append(settingsMenuParam, 'open');
        return prev;
      });
    }
  }

  return (
    <div className='navbar-container'>
      <div className="action-buttons">
        <NavButton className='chat-btn' type={EAppStageContentType.CHAT} defaultIcon={chatMaxIcon} activeIcon={chatMaxActiveIcon} />
        <NavButton className='tasks-btn' type={EAppStageContentType.TASKS} defaultIcon={tasksIcon} activeIcon={tasksActiveIcon} />
        <NavButton className='planner-btn' type={EAppStageContentType.PLANNER} defaultIcon={plannerIcon} activeIcon={plannerActiveIcon} />
      </div>
      <button className="user-avatar" onClick={onOpenSettingsMenu}>
        <Avatar
          className='navbar-avatar'
          defaultAvatarClassName='default-header-avatar'
          size={40}
          avatarImg={userInfo?.profileImage}
          defaultContent={getInitials((userInfo as IUser)?.name || (userInfo as IUser)?.email)}
        />
      </button>
    </div>
  )
}
